<template>
  <img v-on:click="clicked" v-bind:src="src" style="cursor: pointer">
</template>

<style lang="scss">
</style>

<script>  

export default {
  data() {
    return {
    }
  },
  props: {
  	src: {
      type: String,
  	},
  },
  computed: {

  },
  methods: {
    clicked() {
      this.$store.commit('displayGallerySetToSrc', {src: this.src})
    },
  },
  components: {
  },
  mounted() {
  }
}

</script>
  
