# ATTRIB: InquireService is Work Product

import {MKHTTP} from 'src/Mwk/MKHTTP'

class InquireService 
  
  present: (str) ->
    if (str == null)
      return false
    if (typeof(str) == 'undefined')
      return false
      
    console.log("Str is", str)
    if (str.trim() == '')
      return false
    return true
    
  
    
  valid: (i) ->
    console.log("validating", i)
    if ! this.present(i.first_name)
      return false
    if ! this.present(i.last_name)
      return false
    if ! this.present(i.email)
      return false
    portsOk = false
    for name, value of i.ports
      if value
        portsOk = true
    if ! portsOk
      if ! i.notAskingHarbors
        return false
    return true
  
  new_inquiry: ->
    {
      ports: {}
    }
    
  submit: (inq) ->
    url = "/inquiries"
    promise = new Promise((resolve, reject) ->
      
      MKHTTP.post(url, {inquiry: inq}, {error: {code: 'N3', message: 'Your inquiry may not have been saved. Please try again or call us.'}}).then((resp) ->
        resolve()
      ).catch((err) ->
        console.log("Inquire REJECTING err", err);
        reject()
      )
    )
    
    promise
    

inquireService = new InquireService()

export { InquireService, inquireService }



