<!--

  Auth: dhersey
  Date: 04/01/2023
  Desc: Simple carousel slideshow for fleet profiles

  Notes:
    time_out: Changes how frequently the images cycle


-->
<template>
  <div class="fleet-slideshow-container">
    <div class="profile-item" v-for="profile in profiles" v-bind:key="profile">
      <img v-bind:src="profile">
      
    </div>
  </div>
</template>
<script>
import fleet_profiles from './fleet-profiles.json'
export default {
  data(){
  return{
      profiles: fleet_profiles,
      slideIndex: 1,
      time_out: 4000,
      cat_profile_index: 3,
    }
  },
  methods: {
    showProfiles(){
      console.log("running showProfiles");
      let i;
      let slides = document.getElementsByClassName("profile-item");
      for (i = 0; i < this.profiles.length; i++) {
        slides[i].style.display = "none"
      }
      this.slideIndex++;
      if(this.slideIndex > slides.length){ this.slideIndex = 1}
      slides[this.slideIndex - 1].style.display = "flex";
      setTimeout(this.showProfiles, this.time_out);
    },
  },
  mounted() {
    this.showProfiles(this.slideIndex);
  },
}
</script>
<style lang="scss" scoped>
  .fleet-slideshow-container{
    position: relative;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  .profile-item{
    display: none;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    animation: fadeInFromNone 0.75s ease-in-out;
    img{
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 60%;
    }
  }
  .cat_profile{
    width: 60%;
    margin-top: 30%;
  }
</style>