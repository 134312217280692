<template>
  <section class="bg-chart-experience" :class="showClass" style="display: none">
    <div class="bgce-tray">
      <div class="inner">
        <span v-on:click="dismiss"><img src="https://cloud.bartonandgray.com/library/ux/interim/icon-close-with-shadow.png"></span>
      </div>
    </div>
    <div class="bgce-chart-image-holder" :style="chartUrlBackground">
    </div>

  </section>
</template>



<style lang="scss">
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/colors';

$bgce-radius: 0.3em;

$chart-edge-to-screen-and-header-percent: 0; /* was 3 */
$cep: $chart-edge-to-screen-and-header-percent; /* Alias it for shorter */

.bg-chart-experience {
  z-index: definitions.$chart-experience-zindex;
  /* 94% of the non-hader portion */
  height: calc((100% - #{definitions.$www2-header-height}) * #{(100 - ($cep * 2)) / 100});
  width: #{(100% - ($cep * 2%))};
  position: fixed;
  /*  top: calc(#{$cep}% + #{definitions.$www2-header-height}); */
  top: #{definitions.$www2-header-height};
  left: #{$cep * 1%};
  
/*  padding: 0.25em;*/

  background-color: none;
  border: none;
  
  display: none;
  &.active {
    display: block !important;
  }
  @include breaks.bg-breakpoint('narrow') {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
    border: initial;
  }

}
.bgce-tray {
  z-index: 9000;
  position: absolute;
  border-top-left-radius: $bgce-radius;
  @include breaks.bg-breakpoint('narrow') {
    border-top-left-radius: initial;
  }
  .inner {
    border-top-left-radius: $bgce-radius;
    @include breaks.bg-breakpoint('narrow') {
      border-top-left-radius: initial;
    }
    position: relative;
    background-color: none;
    display: flex;
    align-items: center;
    img {
      $h: 3.5em;
      height: $h;
      width: $h;
    }
    > * {
      cursor: pointer;
      padding: 1em;
    }
  }
}
.bgce-label {
  
}
.bgce-chart-image-holder {
  z-index: 8000;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.bgce-chart-image {
  max-width:100%;
  max-height:100%;
  width: auto;
  height: auto;
}
</style>



<script>  
import { portService } from 'src/Realm/Harbors/PortService'
  
export default {
    data() {
      return {
        active: false,
        key: null,
        port: {},
        isDebug: false,
      }
    },
    
    computed: {
      showClass: function() {
        if (this.active) {
          return 'active'
        }
      },
      chartUrl: function() {
        if (this.port) {
          return `https://cloud.bartonandgray.com/library/charts/harbors/chart-${this.port.key}.jpg`
        }
      },
      chartUrlBackground: function() {
        if (this.port) {
          if(this.isDebug){
            console.log("ChartUrlBackground with port", this.port)
          }
          return {
            backgroundImage: `url(https://cloud.bartonandgray.com/library/charts/harbors/chart-${this.port.key}.jpg)`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }
        } else {
          /* Never actually shows -- blurred spectre image */
          return {
            backgroundImage: 'url(http://cloud.bartonandgray.com/library/ux/spectre-chart.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(1em)', /* ', opacity(0.5)', */
          }
        }
      }
    },
    methods: {
      monitor() {
      },
      dismiss() {
        // this.active = false;
        if(this.isDebug){
          console.log("dismissing chart experience");
        }
        this.$store.commit('selectChartExperienceKey', {key: null});
      },
      externalDismiss() {
        // called from window env outside vue app
        this.dismiss()
      },
      switchToKey(key) {
        this.key = key;
        this.port = {};
        if (key) {
          this.active = true;
          // Get Market Harbor version of Port
          portService.marketHarborForKey(key).then((port) => {
            if(this.isDebug){
              console.log("ChartExp got port/marketHarbor", port)
            }
            this.port = port
          })
        } else {
          this.active = false;
        }
      }
    },
    components: {
    },
    created() {
      window.bgChartExperience = {
        component: this
      }
      if(this.isDebug){
        console.log("bg-chart-experience component created")
      }
      this.$store.watch((state) => {
        var r = state.chartExperienceKey;
        // console.log("watch", r);
        return r;
      }, () => {
        var key = this.$store.state.chartExperienceKey;
        // console.log("New Key is", key);
        this.switchToKey(key);
      })
    },
    beforeMount() {
      // this.$store.commit('selectChartExperienceKey', {key: 'boston'});
    }
    
}
</script>
  
