import { createApp } from 'vue';
import { createStore } from 'vuex'

import Bginquiry from 'src/Www2/Bginquiry'
import BgChartExperience from 'src/Www2/BgChartExperience'
import BgChartSelector from 'src/Www2/BgChartSelector'

// ORIGINAL v1 for PRODUCTION
//import BgGallery from 'src/Www2/BgGallery'

// DEVELOPMENT v2 DON'T COMMIT THIS ONE
import BgGallery from 'src/Www2/BgGalleryLoupe'

import BgGalleryLaunchImage from 'src/Www2/BgGalleryLaunchImage'
import BgGalleryLauncher from 'src/Www2/BgGalleryLauncher'
import BgMagnifyLoupe from 'src/Www2/BgMagnifyLoupe'
import BgExperienceCarousel from 'src/Www2/BgExperiences/BgExperienceCarousel'
import BgExperiencesExpanded from 'src/Www2/BgExperiences/BgExperiencesExpanded'
import BgThumbnailCarousel from 'src/Www2/BgCarousel/BgThumbnailCarousel'
import BgFleetProfileCarousel from 'src/Www2/BgCarousel/BgFleetProfileCarousel'
import BgCataCalendar from 'src/Www2/BgCalendars/BgCataCalendar';

import fleetGallery from 'src/V3/BgGalleries/fleetGallery';
import Squadron from '../V3/Squadron/Squadron.vue';

/* V3 */
import BgCataCalendarV2 from 'src/Www2/BgCalendars/BgCatCalendarV2';
import BgLogoFlipper from 'src/Www2/BgLogoFlipper';
import BgV3Gallery from 'src/Www2/BgV3Gallery/BgV3Gallery';
import CuisineNav from 'src/V3/BgNav/CuisineNav';
import CruisingNav from '../V3/BgNav/CruisingNav.vue';
import BgInquiryForm from 'src/V3/BgInquiryForm';
import imageCycler from '../V3/BgGalleries/imageCycler.vue';

import BgPlanVoyage from '../V3/BgPlanVoyage/BgPlanVoyage.vue';

// As the app grows, we may move/refactor this into files etc...
const www2_store = createStore({
  state () {
    return {
      chartExperienceKey: null,
      galleryImageUrls: [],
      galleryIndex: null,
    }
  },
  mutations: {
    displayGallery(state) {
      state.galleryIndex = 0;
      console.log("initializeGallery STORE gallery index is", state.galleryIndex)
      
    },
    initializeGallery(state, payload) {
      console.log('initializeGallery Got scan payload', payload)
      var domSelector = payload.selector
      
      var fullSelector = `${domSelector} img`
      console.log("initializeGallery fullSelector", fullSelector);
      var imgs = document.querySelectorAll(fullSelector);
      console.log("initializeGallery imgs are", imgs)
      
      
      var toAdd = Array.from(imgs).map(function(el) {
        var a = el.attributes;
        var attr = (a.src || a['elevator-src']);
        if (attr) {
          return attr.value
        } else {
          return null
        }

      })
      
      toAdd.forEach(function(el) {
        state.galleryImageUrls.push(el)
      })
      
      state.galleryImageUrls = toAdd
      
      
      console.log("initializeGallery STORE gallery image URLs are", state.galleryImageUrls)
      console.log("initializeGallery STORE first gallery image URL is", state.galleryImageUrls[0])

      
    },
    displayGallerySetToSrc(state, payload) {
      // console.log("displaygallerysettosrc src is", payload.src)
      // console.log("displaygallerysettosrc state.galleryImageUrls", state.galleryImageUrls)
      var newidx = state.galleryImageUrls.indexOf(payload.src);
      // console.log("displaygallerysettosrc newidx", newidx)
      if (newidx >= 0) {
        state.galleryIndex = newidx;
      }
    },
    moveGallery(state, payload) {
      var newidx = state.galleryIndex += payload.offset;
      var glen = state.galleryImageUrls.length;
      if (newidx < 0) {
        newidx = glen - 1;
      }
      if (newidx >= glen) {
        newidx = 0
      }
      state.galleryIndex = newidx;
    },
    closeGallery(state, payload) {
      state.galleryIndex = null;
      
    },
    selectGalleryIndex(state, payload) {
      console.log("Got payload", payload)
      var index = payload.index;
      state.galleryIndex = index;
    },
    selectChartExperienceKey(state, payload) {
      // console.log("Got payload", payload);
      var key = payload.key;
      state.chartExperienceKey = key;
    },
  }
})

var VueAppData = {
  data() {
    return {}
  },
  components: {
    Bginquiry, 
    BgChartExperience, 
    BgChartSelector, 
    BgGallery, 
    BgGalleryLauncher, 
    BgGalleryLaunchImage, 
    BgMagnifyLoupe, 
    BgExperienceCarousel,
    BgExperiencesExpanded, 
    BgThumbnailCarousel,
    BgFleetProfileCarousel,
    BgCataCalendar,
    BgCataCalendarV2,
    BgLogoFlipper,
    BgV3Gallery,
    fleetGallery,
    CuisineNav,
    CruisingNav,
    Squadron,
    BgInquiryForm,
    BgPlanVoyage,
    imageCycler
  }
};

var www2_app = createApp(VueAppData);


www2_app.use(www2_store);

export {www2_app}

// can also listen for "load" which has loaded everything
window.addEventListener('DOMContentLoaded', (event) => {
  www2_app.mount('#www2-app');
})




