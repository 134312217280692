import { www2_app } from 'src/Www2/www2_app'

www2_app.component('fleet-menu', {
  props: {
    initSelected: {
      default: 'overview'
    }
  },
  computed: {
     selected: {
       get: function() {
          return this.selectedValue || this.initSelected
       },
       set: function(v) {
          this.selectedValue = v
       },
    },
  },
  data: function() {
    return {
      selectedValue: null
    }
  },
  // NB: If v-if is used to hide, it prevents load-image-on-scroll etc.
  // So we use v-show instead of v-if
  template: `
  <section>
    <div class="bg-menu">
      <label class="" v-on:click="selected = 'overview'" :class="{on: (selected == 'overview')}">
        <div class="bg-menu-label">OVERVIEW</div>
      </label>
      <label class="" v-on:click="selected = 'gallery'"  :class="{on: (selected == 'gallery')}">
        <div class="bg-menu-label">GALLERY</div>
      </label>
      <label class="" v-on:click="selected = 'squadron'" :class="{on: (selected == 'squadron')}">
        <div class="bg-menu-label">SQUADRON</div>
      </label>
    </div>


    <div v-show="selected == 'overview'">
      <slot name="overview"></slot>
    </div>
    <div v-show="selected == 'gallery'">
      <slot name="gallery"></slot>
    </div>
    <div v-show="selected == 'squadron'">
      <slot name="squadron"></slot>
    </div>
  </section>
  `
});