<!--
  Author: Daniel Hersey

  Desc: Component that generates gallery with thumbnails given array of img urls

  props: :images - 1D Array of img urls
-->
<template>
  <div class="slideshow-container">

    <div class="gallerySlides" v-for="(image, count) in gallery" v-bind:key="image">
      <div class="slide-number">{{ count + 1 }} / {{ gallery.length }}</div>
      <div class="bg-image-holder">
        <bg-magnify-loupe :src="image" :zoom=1.5 :radius=15 style="width: 100%" />
      </div>
    </div>

    <div class="prev"  @click="plusSlides(-1)">&#10094;</div>
    <div class="next"  @click="plusSlides(1)">&#10095;</div>

    <div class="thumbnail-row">
      <div class="thumbnail-container" :style="{ width: thumbnailWidth }" v-for="(image, count) in gallery" v-bind:key="image">
        <img class="thumbnail cursor" :src="image" style="width: 100%;" @click="currentSlide(count + 1)" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import BgMagnifyLoupe from '../BgMagnifyLoupe.vue'
export default {
  data() {
    return {
      slideIndex: 1,
      thumbnailWidth: '16.66%',
      gallery: this.images
    }
  },
  components: {
    BgMagnifyLoupe
  },
  props:{
    images:{
      type: Array,
      required: true
    }
  },
  methods: {
    setThumbnailWidth(){
      let imageCount = this.gallery.length;
      let x = (100 / imageCount).toFixed(2);
      this.thumbnailWidth = x + '%'
    },
    plusSlides(n){
      this.showSlides(this.slideIndex += n);
    },
    currentSlide(n){
      this.showSlides(this.slideIndex = n);
    },
    showSlides(n){
      let i;
      let slides = document.getElementsByClassName("gallerySlides");
      let dots = document.getElementsByClassName("thumbnail");
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1){this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++){
        slides[i].style.display = "none";
      }
      for (i = 0; i< dots.length; i++){
        dots[i].className = dots[i].className.replace(" active", "")
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += "  active";
    }
  },
  mounted() {
    this.showSlides(this.slideIndex);
    this.setThumbnailWidth();
  },
}
</script>

<style lang="scss" scoped>
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/colors';
@use 'stylesheets-bridge/animations';

.slideshow-container{
  margin: 0 auto;
  background-color: black;
  position: relative;
  max-width: 70%;
  @include breaks.bg-breakpoint('narrow'){
    max-width: 90%;
  }
  @include breaks.bg-breakpoint('very-narrow'){
    max-width: 100%;
  }
}

.slide-number{
  color: white;
  text-align: center;
  height: 1.25em;
  padding: 0;
  font-size: 2em;
  @include breaks.bg-breakpoint("very-narrow"){
    font-size: 1.25em;
  }
}

.gallerySlides {
  display: none;
  animation: fadeInFromHalf;
  animation-duration: 0.5s;
}

.cursor{
  cursor: pointer;
}

.prev, .next{
  cursor: pointer;
  position: absolute;
  top: calc(50% - 1.25em); /* takes slide number into account */
  width: auto;
  padding: 1em;
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 1.875em;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  background-color: rgba(0, 0, 0, 0.5); /* added here cus there may be white images*/
  &:hover{
    transition: .2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.8);
  }
  @include breaks.bg-breakpoint('very-narrow'){
    font-size: 1.15em;
    padding: 2em 0.75em;
  }
}

.next{
  right: 0;
  border-radius: 3px 0 0 3px;
}

.thumbnail-row{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}

.thumbnail-container{
  margin-top: .5em;
  float: left;
  @include breaks.bg-breakpoint('very-narrow'){
    /* No thumbnails in mobile view */
    display: none;
  }
}

.thumbnail {
  opacity: 0.6;
  &:hover{
    transition: .2s ease-in-out;
    opacity: 1;
  }
}

.active{
  opacity: 1;
}

</style>