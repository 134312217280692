<template>
  <div class="layout-2-3col">
    <div class="col-30">

      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Adventure Time?</div>
        <p class="v4-base-paragraph color-storm">
          The following times are possible for your location choices,  please let us know which time frames work for your crew.
        </p>
      </div>

      <!-- Destinations sec -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">DESTINATIONS</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="destination in destinationsValue" v-bind:key="destination">{{ destination }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 0)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT DESTINATIONS</div>
          </div>
        </div>

        <!-- Legend -->
        <div class="legend-wrapper">
          <div class="legend-row">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">AVAILABLE</div>
          </div>
          <div class="legend-row">
            <svg class="checkmark selected" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">YOUR SELECTION</div>
          </div>
          <div class="legend-row">
            <img style="width: 35px;" src="https://cloud.bartonandgray.com/library/ux/icons/V3/icon-x.png">
            <div class="v4-base-header-nova color-gradient-shark">NOT AVAILABLE</div>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT COL -->
    <div class="col-60">

      <!-- Bahamas -->
      <div v-if="destinations.includes('Bahamas')" class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">Bahamas</div>
        <div class="destination-content">
          <div class="left">
            <p class="v4-base-paragraph color-white-cap">Popular anchorages in the region</p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">Abacos</li>
              <li class="color-gold">Nassau</li>
              <li class="color-gold">Exumas</li>
            </ul>
          </div>
          <div class="right">
            <div class="date-option" :class="isSelected('November (Bahamas)')" @click="toggleSelected('November (Bahamas)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">NOVEMBER</div>
            </div>
            <div class="date-option" :class="isSelected('December (Bahamas)')" @click="toggleSelected('December (Bahamas)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">DECEMBER</div>
            </div>
            <div class="date-option" :class="isSelected('May (Bahamas)')" @click="toggleSelected('May (Bahamas)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">MAY</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Northeast -->
      <div v-if="destinations.includes('The Northeast')" class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">The Northeast</div>
        <div class="destination-content">
          <div class="left">
            <p class="v4-base-paragraph color-white-cap">Popular anchorages in the region</p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">NYC</li>
              <li class="color-gold">Greenwich</li>
              <li class="color-gold">Fisher's Island</li>
              <li class="color-gold">The Hamptons</li>
              <li class="color-gold">Montauk</li>
              <li class="color-gold">Block Island</li>
              <li class="color-gold">Newport</li>
              <li class="color-gold">Martha's Vineyard</li>
              <li class="color-gold">Nantucket</li>
              <li class="color-gold">Boston</li>
              <li class="color-gold">Maine</li>
            </ul>
          </div>
          <div class="right">
            <div class="date-option" :class="isSelected('June (Northeast)')" @click="toggleSelected('June (Northeast)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">JUNE</div>
            </div>
            <div class="date-option" :class="isSelected('July (Northeast)')" @click="toggleSelected('July (Northeast)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">JULY</div>
            </div>
            <div class="date-option" :class="isSelected('August (Northeast)')" @click="toggleSelected('August (Northeast)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">AUGUST</div>
            </div>
            <div class="date-option" :class="isSelected('September (Northeast)')" @click="toggleSelected('September (Northeast)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">SEPTEMBER</div>
            </div>
            <div class="date-option" :class="isSelected('October (Northeast)')" @click="toggleSelected('October (Northeast)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">OCTOBER</div>
            </div>
          </div>
        </div>
      </div>
      <!-- St-barths -->
      <div v-if="destinations.includes('St Barths')" class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">St Barths &amp; Anguilla</div>
        <div class="destination-content">
          <div class="left">
            <p class="v4-base-paragraph color-white-cap">Popular anchorages in the region</p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">St Maarten</li>
              <li class="color-gold">St Barths</li>
              <li class="color-gold">Anguila</li>
            </ul>
          </div>
          <div class="right">
            <div class="date-option" :class="isSelected('February (St-Barths)')" @click="toggleSelected('February (St-Barths)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">FEBRUARY</div>
            </div>
            <div class="date-option" :class="isSelected('March (St-Barths)')" @click="toggleSelected('March (St-Barths)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">MARCH</div>
            </div>
            <div class="date-option" :class="isSelected('April (St-Barths)')" @click="toggleSelected('April (St-Barths)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">APRIL</div>
            </div>
          </div>
        </div>
      </div>

      <!-- VI -->
      <div v-if="destinations.includes('Virgin Islands')" class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">The Virgin Islands</div>
        <div class="destination-content">
          <div class="left">
            <p class="v4-base-paragraph color-white-cap">Popular anchorages in the region</p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">St Thomas</li>
              <li class="color-gold">St John</li>
              <li class="color-gold">Tortola</li>
              <li class="color-gold">Virgin Gorda</li>
              <li class="color-gold">Anegada</li>
            </ul>
          </div>
          <div class="right">
            <div class="date-option" :class="isSelected('December (Virgin Islands)')" @click="toggleSelected('December (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">DECEMBER</div>
            </div>
            <div class="date-option" :class="isSelected('January (Virgin Islands)')" @click="toggleSelected('January (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">JANUARY</div>
            </div>
            <div class="date-option" :class="isSelected('February (Virgin Islands)')" @click="toggleSelected('February (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">FEBRUARY</div>
            </div>
            <div class="date-option" :class="isSelected('March (Virgin Islands)')" @click="toggleSelected('March (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">MARCH</div>
            </div>
            <div class="date-option" :class="isSelected('April (Virgin Islands)')" @click="toggleSelected('April (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">APRIL</div>
            </div>
            <div class="date-option" :class="isSelected('May (Virgin Islands)')" @click="toggleSelected('May (Virgin Islands)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">MAY</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sig-cruises -->
      <div v-if="destinations.includes('Signature Cruises')" class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">Signature Cruises</div>
        <div class="destination-content">
          <div class="left">
            <p class="v4-base-paragraph color-white-cap">Popular anchorages in the region</p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">Newport Folk Fest</li>
              <li class="color-gold">Newport Jazz Fest</li>
              <li class="color-gold">Opera House Cup</li>
              <li class="color-gold">St Barths Bucket Regatta</li>
              <li class="color-gold">4th of July Fireworks in NYC</li>
            </ul>
          </div>
          <div class="right">
            <div class="date-option" :class="isSelected('July (Signature Cruises)')" @click="toggleSelected('July (Signature Cruises)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">JULY</div>
            </div>
            <div class="date-option" :class="isSelected('August (Signature Cruises)')" @click="toggleSelected('August (Signature Cruises)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">AUGUST</div>
            </div>
            <div class="date-option" :class="isSelected('March (Signature Cruises)')" @click="toggleSelected('March (Signature Cruises)')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">MARCH</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Button -->
      <div v-if="selectedDates.length > 0" @click="$emit('close', selectedDates)" style="width: 300px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">NEXT: ACTIVITIES</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations', 'dates']);
  const destinationsValue = ref(props.destinations);

  let selectedDates = ref(props.dates);

  function toggleSelected(date){
    if (selectedDates.value.includes(date)) {
      selectedDates.value.splice(selectedDates.value.indexOf(date), 1);
    } else {
      selectedDates.value.push(date);
    }
  }

  function isSelected(date){
    if (selectedDates.value.includes(date)) {
      return 'selected';
    } else{
      return;
    }
  }

  watch(() => props.destinations, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    destinationsValue.value = newValue;
  }); 
</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .legend-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .legend-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
    }
  }

  .checkmark{
    path{
      fill: $v3-french-blue;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

  .destination-wrapper{
    border: 1px solid black;
    margin-bottom: 2em;
    .v4-section-header-heavy{
      background: $gradient-shark !important;
      padding: 0.45em 0 0.35em 0.75em;
    }
  }

  .destination-content{
    background: $gradient-sky !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-left: 2em;
    padding-bottom: 2em;
    padding: 1.5em 2em 2em 2em;
    @media only screen and (max-width: 1281px){
      flex-direction: column;
    }
    .date-option{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      background: $gradient-cloud !important;
      border: 1px dashed black;
      width: 400px;
      max-width: 100%;
      margin-bottom: 1.5em;
      padding: 0.75em 0 0.75em 1em;
      transition: 0.15s ease-in-out;
      &:hover{
        filter: brightness(90%);
        cursor: pointer;
      }
      &.selected{
        path{
          fill: $v3-port;
        }
      }
    }
  }
</style>