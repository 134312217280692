<!--

  Author: 
  components: { BgFormTracker },dhersey 11/04/2024
  Desc: Component for "Plan your Voyage" multi-step inquiry form
    for new Adventure Cruising 2024 project

-->

<template>

  <div class="navigation-wrapper">
    <form-tracker :step="currentStep"/>
  </div>

  <!-- 1 - Destinations -->
  <section class="destinations-wrapper" :class="isActiveStep(0)">
    <destinations 
    :destinations="selectedDestinations" 
    @close="(i) => leaveDestinations(i)" />
  </section>

  <!-- 2 - Timeframe -->
  <section class="timeframe-wrapper" :class="isActiveStep(1)">
    <timeframe 
      :destinations="selectedDestinations" 
      :dates="selectedDates" 
      @changeStep="(i) => changeStep(i)" 
      @close="(i) => leaveTimeframe(i)" />
  </section>

  <!-- 3 - Activities -->
  <section class="activities-wrapper" :class="isActiveStep(2)">
    <activities 
      :destinations="selectedDestinations" 
      :dates="selectedDates" 
      :options="selectedOptions" 
      @changeStep="(i) => changeStep(i)" 
      @close="(i) => leaveActivities(i)"/>
  </section>

  <!-- 4 - Contact -->
  <section class="contact-wrapper" :class="isActiveStep(3)">
    <contact 
      :destinations="selectedDestinations" 
      :dates="selectedDates" 
      :options="selectedOptions" 
      @changeStep="(i) => changeStep(i)" 
      @close="(i) => leaveContact(i)"/>
  </section>

  <!-- 5 - Voyage Summary -->
  <section class="contact-wrapper" :class="isActiveStep(4)">
    <voyage-summary 
      :destinations="selectedDestinations" 
      :dates="selectedDates" 
      :options="selectedOptions" 
      :user="userData"/>
  </section>

</template>

<script setup>
  import { ref, toRaw } from 'vue'
  import FormTracker from './FormTracker.vue'; 
  import Destinations from './Destinations.vue';
  import Timeframe from './Timeframe.vue';
  import Activities from './Activities.vue';
  import Contact from './Contact.vue';
  import VoyageSummary from './VoyageSummary.vue';

  let currentStep = ref(0);  
  let selectedDestinations = ref([]); 
  let selectedDates = ref([]); 
  let selectedOptions = ref([]); 

  // fname, lname, email, phone, numGuests, hearAbout
  let userData = ref({}); 

  function leaveDestinations(i){
    selectedDestinations.value = i;
    currentStep.value += 1;
  }

  function leaveTimeframe(i){
    selectedDates.value = i;
    currentStep.value += 1;
  }

  function leaveActivities(i){
    selectedOptions.value = i;
    currentStep.value += 1;
  }

  function leaveContact(i){
    userData.value = i;
    currentStep.value += 1;
    sendEmail();
  }

  function changeStep(i){
    currentStep.value = i;
  }
  
  function isActiveStep(i){
    if (i == currentStep.value) {
      return 'active';
    } else {
      return;
    }
  }

  function sendEmail(){
    let endpoint = '/adventure-cruising/adventure-cruising-interest-post';    
    
    let data = {
      'selectedDestinations': toRaw(selectedDestinations.value),
      'selectedDates': toRaw(selectedDates.value),
      'selectedOptions': toRaw(selectedOptions.value),
      'userData': toRaw(userData.value)
    }

    console.log(JSON.stringify(data));
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    
    fetch(endpoint, {
      method: 'POST',
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedDestinations: toRaw(selectedDestinations.value), selectedDates: toRaw(selectedDates.value), selectedOptions: toRaw(selectedOptions.value), userData: userData.value })
    }).then(response => response.json())
      .then(data => {
        console.log('Success: ', data);
      })
      .catch((e) => {
        console.log('Error: ', e, ' Data: ', data)
      })
  }

</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/v3/abstracts/colors';
  
section{
  &:not(.active){
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: hidden;
  }
}

</style>