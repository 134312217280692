<!--
  
  Author: dhersey
  Date: 02/17/2023
  Desc: The new V3 Button

  props:
    -label(String, REQUIRED): label of button, make sure to wrap String with single AND double quotes
      ex: :label="'HARBORS &AMP; ISLANDS'"

    -burgee(Boolean, NOT REQUIRED): Used to disable flag on button
      ex: :burgee="false"

    -shadow(Boolean, NOT REQUIRED): used to give a light shadow to button
      ex: :shadow="true" 

  notes: 
    OG button:
      <a href="">
        <div class="bg-button-v3">
          <img src="https://cloud.bartonandgray.com/library/brand/burgee-pole.svg">
          <div class="bg-button-v3-label">INQUIRE TO LEARN MORE</div>
        </div>
      </a>

-->

<template>
  <div class="bg-button-v3" :class="buttonStyleHandler()">
    <img v-if="burgee" src="https://cloud.bartonandgray.com/library/brand/burgee-pole.svg">
    <div class="bg-button-v3-label">{{ label }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  props:{
    label: {
      type: String,
      required: true
    },  
    burgee: {
      type: Boolean,
      required: false,
      default: true
    },
    shadow:{
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods:{
    buttonStyleHandler(){
      let cname;
      if(this.shadow){
        cname = "shadow";
      }

      return cname;

    }
  },
  mounted() {
    console.log("burgee: ", this.burgee);
  },
}
</script>
<style lang="scss" scoped>
$color-fog: #e6e7e8;
$v3-french-blue: #4a8ac9;
$v3-color-navy: #324954;


.bg-button-v3{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  background: $color-fog;
  border-radius: 8px;
  border: 1px solid $v3-french-blue;
  color: $v3-color-navy;
  padding: 0.5em 1em;
  padding-right: 2em;
  transition: .15s ease-in-out;
  img{
    width: 2em;
  }
  .bg-button-v3-label{
    margin-left: 1em;
  }
  &:hover{
    filter: brightness(90%);
  }
}

.shadow{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.13);
}

</style>