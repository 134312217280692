<template>
  <div v-on:click="clicked" class="bgyw-button full bg-hover medium darken">
    <div class="bgyw-button-label">ZOOM & VIEW GALLERY</div>
  </div>
</template>

<style lang="scss">
</style>

<script>  

export default {
  data() {
    return {
    }
  },
  props: {
  	label: {
      type: String,
  	},
  	gallerykey: {
      type: String,
  	},
    domSelector: {
      type: String,
    }
    
  },
  computed: {

  },
  methods: {
    clicked() {
      console.log("GALLERY CLICK", this.gallerykey, this.label);
      this.$store.commit('displayGallery');
    },
  },
  components: {
  },
  mounted() {
    this.$store.commit('initializeGallery', {selector: this.domSelector});
    console.log(`Gallery launched with ${this.gallerykey}`)
  }
}

</script>
  
