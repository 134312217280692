<template>
  <span v-on:click="clicked">{{ props.label }}</span>
</template>

<script setup>  
import { defineProps } from 'vue';

const props = defineProps({
  label: String,
  harborkey: String
})

function clicked(){
  window.location = `/harbors/${props.harborkey}`;
}
</script>
  
