<!--

Author: dhersey

Date: ~6/3/22 
Desc: 
  This component mainly holds the carousel for the experience section.
  Links to mobile view as well, which holds BgExperiencesExpanded component
  

Props:
  season: Which season data file you want to display ("'winter'" for ex)
    defaults to "'summer'"

Notes: UPDATED FOR V3 ~ 04/24/2023

-->


<template>
  <div>
    <div class="not-narrow"><!-- Desktop Only -->
      <div class="bg-carousel" style="display: flex; flex-direction: column; justify-content: center; margin: auto;"><!-- WRAP COMPONENT IN UNIQUE STYLES -->
        <div class="slideshow-container" > <!-- Wrapper Div -->
          <div class="slideshow-scene" style="display: block;"><!-- SCENE 1 -->
            <div class="slideshow-row"><!-- ROW -->
              <div class="maxi">
                <img id="event_0_img" v-bind:src="this.experiences[0].img_src">
                <div class="bg-headline">{{ this.experiences[0].title }}</div>
              </div>
              <div class="mini">
                <img id="event_1_img" v-bind:src="this.experiences[1].img_src">
                <div class="bg-headline">{{ this.experiences[1].title }}</div>
              </div>
            </div>
            <div class="slideshow-row"><!-- ROW -->
              <div class="mini">
                <img id="event_2_img" v-bind:src="this.experiences[2].img_src">
                <div class="bg-headline">{{ this.experiences[2].title }}</div>
              </div>
              <div class="maxi">
                <img id="event_3_img" v-bind:src="this.experiences[3].img_src">
                <div class="bg-headline">{{ this.experiences[3].title }}</div>
              </div>
            </div>
          </div>
          <div class="slideshow-scene" style="display: none;"><!-- SCENE 2 -->
            <div class="slideshow-row"><!-- ROW -->
              <div class="maxi">
                <img id="event_4_img" v-bind:src="this.experiences[4].img_src">
                <div class="bg-headline">{{ this.experiences[4].title }}</div>
              </div>
              <div class="mini">
                <img id="event_5_img" v-bind:src="this.experiences[5].img_src">
                <div class="bg-headline">{{ this.experiences[5].title }}</div>
              </div>
            </div>
            <div class="slideshow-row"><!-- ROW -->
              <div class="mini">
                <img id="event_6_img" v-bind:src="this.experiences[6].img_src">
                <div class="bg-headline">{{ this.experiences[6].title }}</div>
              </div>
              <div class="maxi">
                <img id="event_7_img" v-bind:src="this.experiences[7].img_src">
                <div class="bg-headline">{{ this.experiences[7].title }}</div>
              </div>
            </div>
          </div>
          <div class="slideshow-scene" style="display: none;"><!-- SCENE 3 -->
            <div class="slideshow-row"><!-- ROW -->
              <div class="maxi">
                <img id="event_8_img" v-bind:src="this.experiences[8].img_src">
                <div class="bg-headline">{{ this.experiences[8].title }}</div>
              </div>
              <div class="mini">
                <img id="event_9_img" v-bind:src="this.experiences[9].img_src">
                <div class="bg-headline">{{ this.experiences[9].title }}</div>
              </div>
            </div>
            <div class="slideshow-row"><!-- ROW -->
              <div class="mini">
                <img id="event_10_img" v-bind:src="this.experiences[10].img_src">
                <div class="bg-headline">{{ this.experiences[10].title }}</div>
              </div>
              <div class="maxi">
                <img id="event_11_img" v-bind:src="this.experiences[11].img_src">
                <div class="bg-headline">{{ this.experiences[11].title }}</div>
              </div>
            </div>
          </div>
          <div class="slideshow-scene" style="display: none;"><!-- SCENE 4 -->
            <div class="slideshow-row"><!-- ROW -->
              <div class="maxi">
                <img id="event_12_img" v-bind:src="this.experiences[12].img_src">
                <div class="bg-headline">{{ this.experiences[12].title }}</div>
              </div>
              <div class="mini">
                <img id="event_13_img" v-bind:src="this.experiences[13].img_src">
                <div class="bg-headline">{{ this.experiences[13].title }}</div>
              </div>
            </div>
            <div class="slideshow-row"><!-- ROW -->
              <div class="mini">
                <img id="event_14_img" v-bind:src="this.experiences[14].img_src">
                <div class="bg-headline">{{ this.experiences[14].title }}</div>
              </div>
              <div class="maxi">
                <img id="event_15_img" v-bind:src="this.experiences[15].img_src">
                <div class="bg-headline">{{ this.experiences[15].title }}</div>
              </div>
            </div>
          </div>
          <div class="slideshow-scene" style="display: none;"><!-- SCENE 5 -->
            <div class="slideshow-row"><!-- ROW -->
              <div class="maxi">
                <img id="event_16_img" v-bind:src="this.experiences[16].img_src">
                <div class="bg-headline">{{ this.experiences[16].title }}</div>
              </div>
              <div class="mini">
                <img id="event_17_img" v-bind:src="this.experiences[17].img_src">
                <div class="bg-headline">{{ this.experiences[17].title }}</div>
              </div>
            </div>
            <div class="slideshow-row"><!-- ROW -->
              <div class="mini">
                <img id="event_18_img" v-bind:src="this.experiences[18].img_src">
                <div class="bg-headline">{{ this.experiences[18].title }}</div>
              </div>
              <div class="maxi">
                <img id="event_19_img" v-bind:src="this.experiences[19].img_src">
                <div class="bg-headline">{{ this.experiences[19].title }}</div>
              </div>
            </div>
          </div>
        </div>

  <!-- CAROUSEL NAVIGATION -->
        <div class="v-small-gutter"></div>
        <section class="bg-scene-full" scene="row-a" elevator-animation-threshold="90">
          <div style="text-align:center">
            <div class="exp-slide-nav-wrapper"><!-- This is going to be the div that has the navigation for the slideshow -->
              <div class="slide-nav-row" style="text-align: center;">
                <img src="https://cloud.bartonandgray.com/library/ux/icons/last-arrow.svg" @click="plusSlides(-1)" class="arrow prev">
                <span class="dot" @click="currentSlide(1)"></span>
                <span class="dot" @click="currentSlide(2)"></span>
                <span class="dot" @click="currentSlide(3)"></span>
                <span class="dot" @click="currentSlide(4)"></span>
                <span class="dot" @click="currentSlide(5)"></span>
                <img src="https://cloud.bartonandgray.com/library/ux/icons/next-arrow.svg" @click="plusSlides(1)" class="arrow next">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

<!-- MOBILE VIEW -->
<!-- SHOWS FIRST 4 EXPERIENCES -->
    <div class="narrow-only"> <!-- Mobile Only -->
      <div class="bg-carousel-mobile">
        <div class="mobile-image-gallery">
          <div v-for="item, count in 4" v-bind:key="item">
            <img v-bind:src="this.experiences[count].img_src">
            <div class="bg-headline">{{ this.experiences[count].title }}</div>
          </div>
        </div>
        <div style="margin: 2em auto;">
          <a href="/more-experiences">
            <bg-button-v-3 :label="'SHOW MORE'"/>
          </a> 
        </div>
      </div>
    </div>
<!-- END Mobile view -->

  </div>
</template>
<script>
import winter_experiences from './pop-experiences/winterExps.json';
import summer_experiences from './pop-experiences/summerExps.json';
import BgButtonV3 from '../../V3/BgUtilities/BgButtonV3.vue';

export default {
  data() {
    return{
      experiences: summer_experiences,
      slideIndex: 1,
    }
  },
  props: {
    season:{
      type: String,
      default: 'summer'
    },
  },
  methods: {
    setEvents(x){
      if(x == 'winter'){
        this.experiences = winter_experiences;
      } else{
        this.experiences = summer_experiences;
      }
    },

    plusSlides(n){
      this.showSlides(this.slideIndex += n);
    },

    currentSlide(n){
      this.showSlides(this.slideIndex = n);
    },

    showSlides(n){
      let i;
      let slides = document.getElementsByClassName("slideshow-scene");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1){this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++){
        slides[i].style.display = "none";
      }
      for (i = 0; i< dots.length; i++){
        dots[i].className = dots[i].className.replace(" dot-active", "")
      }
      dots[this.slideIndex - 1].className += " dot-active";
      slides[this.slideIndex - 1].style.display = "block";
    }
  },
  components:{
    BgButtonV3
  },
  mounted(){
    this.setEvents(this.season);
    console.log("this.season: ", this.season);
    this.showSlides(this.slideIndex);
  }
}
</script>
<style lang="scss">
  // Not scoped because that causes issues with the support media query?
  @use 'stylesheets-bridge/www2/breaks';
  @use 'stylesheets-bridge/www2/ratios';
  @use 'stylesheets-bridge/www2/definitions';
  @use 'stylesheets-bridge/colors';
  @use 'stylesheets-bridge/animations';

  .slideshow-container{
    max-width: 1440px;
    margin: auto;
    .slideshow-row{
      display: flex;
      gap: 2em;
      margin-bottom: 1em;
      .bg-headline{
        padding-top: .5em;
      }
    }
  }

  .slideshow-scene{
    animation-name: fadeInFromNone;
    animation-duration: .5s;
  }

  .maxi{
    max-width: 66.5%;
    display: flex;
    flex-direction: column;
    img{
      height: 335px;
    }
  }
  .mini{
    max-width: 33.5%;
    display: flex;
    flex-direction: column;
    img{
      height: 335px;
    }
  }

  .bg-carousel-mobile{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
  }

  .mobile-image-gallery{
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    gap: 2em;
    img{
      max-width: 100%;
    }
  }
</style>  