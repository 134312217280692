<template>
  <div class="layout-3col" style="">

    <!-- Left -->
    <div class="col-1">
      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Activities & Cuisine</div>
        <p class="v4-base-paragraph color-storm">
          Food, beverage, culture, watersports, active, chill, let us know how you like to get away.
        </p>
      </div>

      <!-- Destinations -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">DESTINATIONS</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="destination in destinationsValue" v-bind:key="destination">{{ destination }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 0)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT DESTINATIONS</div>
          </div>
        </div>
      </div>

      <!-- Timeframe -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">TIMEFRAME</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="date in datesValue" v-bind:key="date">{{ date }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 1)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT TIMEFRAMES</div>
          </div>
        </div>
      </div>

      <!-- Legend -->
      <div class="legend-wrapper">
        <div class="legend-row">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">AVAILABLE</div>
        </div>
        <div class="legend-row">
          <svg class="checkmark selected" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">YOUR SELECTION</div>
        </div>
        <div class="legend-row">
          <img style="width: 35px;" src="https://cloud.bartonandgray.com/library/ux/icons/V3/icon-x.png">
          <div class="v4-base-header-nova color-gradient-shark">NOT AVAILABLE</div>
        </div>
      </div>

    </div>

    <!-- Middle -->
    <div class="col-2">
      <div style="margin-bottom: 2em;">
        <div class="v4-base-header-nova color-gradient-shark">Activities</div>
        <p class="v4-base-paragraph color-storm">
          Please select the types of activities you and your crew are interested on your cruise.
        </p>
      </div>
      <div class="button-selection-list activities">
        <div class="button-option" :class="isSelected('Snorkel / Scuba')" @click="toggleSelected('Snorkel / Scuba')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">SNORKEL / SCUBA</div>
        </div>
        <div class="button-option" :class="isSelected('Bar Hopping')" @click="toggleSelected('Bar Hopping')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">BAR HOPPING</div>
        </div>
        <div class="button-option" :class="isSelected('Sailing / Kiting')" @click="toggleSelected('Sailing / Kiting')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">SAILING / KITING</div>
        </div>
        <div class="button-option" :class="isSelected('Hiking / Excercise')" @click="toggleSelected('Hiking / Excercise')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">HIKING / EXCERCISE</div>
        </div>
        <div class="button-option" :class="isSelected('Shopping / Culture')" @click="toggleSelected('Shopping / Culture')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">SHOPPING / CULTURE</div>
        </div>
        <div class="button-option" :class="isSelected('Wellness / Recharge')" @click="toggleSelected('Wellness / Recharge')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">WELLNESS / RECHARGE</div>
        </div>
        <div class="button-option" :class="isSelected('Events & Festivities')" @click="toggleSelected('Events & Festivities')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">EVENTS &AMP; FESTIVITIES</div>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="col-3">
      <div style="margin-bottom: 2em;">
        <div class="v4-base-header-nova color-gradient-shark">Cuisine</div>
        <p class="v4-base-paragraph color-storm">
          Please select the types of activities you and your crew are interested on your cruise.
        </p>
      </div>
      <div class="button-selection-list cuisine">
        <div class="button-option" :class="isSelected('Local Seafood')" @click="toggleSelected('Local Seafood')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">LOCAL SEAFOOD</div>
        </div>
        <div class="button-option" :class="isSelected('International')" @click="toggleSelected('International')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">INTERNATIONAL</div>
        </div>
        <div class="button-option" :class="isSelected('Restaurants / Restorts')" @click="toggleSelected('Restaurants / Restorts')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">RESTAURANTS / RESORTS</div>
        </div>
        <div class="button-option" :class="isSelected('Healthy')" @click="toggleSelected('Healthy')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">HEALTHY</div>
        </div>
        <div class="button-option" :class="isSelected('Light Bites / Grazing')" @click="toggleSelected('Light Bites / Grazing')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">LIGHT BITES / GRAZING</div>
        </div>
        <div class="button-option" :class="isSelected('Wine, Beer & Mixology')" @click="toggleSelected('Wine, Beer & Mixology')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">WINE, BEER &AMP; MIXOLOGY</div>
        </div>
        <div class="button-option" :class="isSelected('Sweet Tooth')" @click="toggleSelected('Sweet Tooth')">
          <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova color-gradient-shark">SWEET TOOTH</div>
        </div>
      </div>
    </div>

  </div>
  <!-- Button -->
  <div v-if="selectedOptions.length > 0" @click="$emit('close', selectedOptions)" style="width: 300px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
      <div class="v4-download-button bg-color-gradient-cloud">
        <div class="soft-square bg-color-gradient-sky">
          <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
        </div>
        <div class="button-label color-gradient-shark">NEXT: REVIEW</div>
      </div>
    </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations', 'dates', 'options']);
  const destinationsValue = ref(props.destinations);
  const datesValue = ref(props.dates);
  
  let selectedOptions = ref(props.options);

  function toggleSelected(option){
    if (selectedOptions.value.includes(option)) {
      selectedOptions.value.splice(selectedOptions.value.indexOf(option), 1);
    } else {
      selectedOptions.value.push(option);
    }
  }

  function isSelected(option){
    if (selectedOptions.value.includes(option)) {
      return 'selected';
    } else{
      return;
    }
  }

  watch(() => props.destinations, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    destinationsValue.value = newValue;
  }); 

  watch(() => props.dates, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    datesValue.value = newValue;
  }); 
</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .legend-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .legend-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
    }
  }

  .checkmark{
    path{
      fill: $v3-french-blue;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

  .button-option{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    background: $gradient-cloud !important;
    border: 1px dashed black;
    width: 400px;
    max-width: 100%;
    margin-bottom: 1.5em;
    padding: 0.75em 0 0.75em 1em;
    transition: 0.15s ease-in-out;
    &:hover{
      filter: brightness(90%);
      cursor: pointer;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

</style>