<template>
  <section class="bg-gallery" :class="showClass" style="display: none">
    <div style="display: flex; height: 100%; flex-direction: column">
      <div style="flex-grow: 100; flex-shrink: 100"></div>
      <div class="bg-image-holder-fixed bg-ratio-947-408" style="flex-shrink: 0">
        <div class="bg-ratio-inner" xstyle="background-color: green">
          <div id="bcge-chart-image-holder" class="bgce-chart-image-holder" 
            :style="galleryUrlBackground" 
            v-on:mousemove="bgMouseMove($event)" 
            v-on:touchstart="bgMouseMove($event)" 
            v-on:touchmove="bgMouseMove($event)" 
            v-on:touchend="bgMouseLeave($event)" 
            v-on:mouseout="bgMouseLeave($event)">
            <!--Put #lens div here to put lens back above the image, still has mouseleave issue this way however-->
          </div>
          <div id="lens" style="z-index: 8001; pointer-events: none" class="magnifying-lens-wrapper" :style="lensStyleHandler">
            <div id="large-img" class="lens" :style="galleryUrlBackgroundLarge"></div>
          </div>
        </div>
      </div>
      <div class="bcge-tray-row">
        <div class="bgce-tray">
          <div class="inner">
            <span v-on:click="go(-1)" style=""><img src="https://cloud.bartonandgray.com/library/ux/icons/last-arrow.svg"></span>
            <span v-on:click="dismiss"><img src="https://cloud.bartonandgray.com/library/ux/interim/icon-close-with-shadow.png"></span>
            <span v-on:click="go(1)" style=""><img src="https://cloud.bartonandgray.com/library/ux/icons/next-arrow.svg"></span>
          </div>
        </div>
      </div>
      <div style="flex-grow: 100; flex-shrink: 100"></div>
    </div>
  </section>
  
</template>



<style lang="scss">
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/colors';
@use 'stylesheets-bridge/animations';

$bgce-radius: 0.3em;

$chart-edge-to-screen-and-header-percent: 0; /* was 3 */
$cep: $chart-edge-to-screen-and-header-percent; /* Alias it for shorter */

.bg-gallery {
  z-index: definitions.$chart-experience-zindex;
  /* 94% of the non-hader portion */
  /* Changed height and top values to be correctly adjusted for navigation */
  height: calc((100% - 4.5em) * #{(100 - ($cep * 2)) / 100});
  width: #{(100% - ($cep * 2%))};
  position: fixed;
  /* top: calc(#{$cep}% + 4.5em); */
  top: 4.5em;
  left: #{$cep * 1%};
/*  padding: 0.25em;*/

  background-color: white;
  border: none;
  
  display: none;
  &.active {
    display: block !important;
  }
  @include breaks.bg-breakpoint('narrow') {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
    border: initial;
  }

}
.bcge-tray-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.bgce-tray {
  margin-top: -5.6em;
  position: relative;
  display: inline-block;
  z-index: 9000;
  background-color: #388bca; /* French blue */
  border-radius: 1.72em;
  padding: 0 0.5em;
  .inner {
    display: flex;
    align-items: center;
    img {
      $h: 3.0em;
      height: $h;
      width: $h;
    }
    > * {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0.2em;
      &:hover{
        filter: brightness(85%);
        transition: 0.2s ease-in-out;
      }
    }
  }
}
.bgce-label {
  
}

.bgce-chart-image-holder {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.bgce-chart-image {
  max-width:100%;
  max-height:100%;
  width: auto;
  height: auto;
}

.magnifying-lens-wrapper {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  // animation: fadeInFromNone 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  left: -100px;
  top: 50%;
  .lens{
    
    // set height and width to x% the width of the viewport (since this gallery goes edge-to-edge,
    // 20vw e.g. results in a 20% width loupe)
    height: 25vw; // was 100px
    width: 25vw; // was 100px

    box-shadow: inset 0 0 10px 15px grey;
    z-index: 11000;
    background-color: white;
    border: 2px solid white;
    border-radius: 50%;
    transition: opacity 0.2s;
  }
  &:hover,
  &:active {
    .lens{
      opacity: 1;
    }
  }
}

</style>



<script>  
// "v2" of BgGallery
// import { portService } from 'src/Realm/Harbors/PortService'
  
export default {
    data() {
      return {
        active: false,
        index: null,
        imageUrl: null,
        galleryImageUrls: [],
        bgXpercent: 50,
        bgYpercent: 50,
        logCount: 0,
        bgSize: '100%',
        lensBgSize: '100%',
        lensState: {
          Left: 0,
          Top: 0,
          Display: false,
        },
      }
    },
    
    computed: {
      showClass: function() {
        if (this.active) {
          return 'active'
        }
      },
      galleryUrlBackground: function() {
        if (this.imageUrl) {
          var url = this.imageUrl;
          console.log("BgGalleryLoupe galleryUrlBackground SHOWING URL", url);
          return {
            backgroundImage: `url(${url})`,
            backgroundSize: '100%',  // always 100% here -- the lens changes
            backgroundPosition: 'center', // always centered here -- the lens changes `${this.bgXpercent}% ${this.bgYpercent}%`,
            backgroundRepeat: 'no-repeat',
          }
        } else {
          console.log("BgGalleryLoupe galleryUrlBackground blank/placeholder");
          /* Never actually shows -- blurred spectre image */
          return {
            backgroundImage: 'url(https://cloud.bartonandgray.com/library/ux/spectre-chart.jpg)',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            filter: 'blur(1em)', /* ', opacity(0.5)', */
          }
        }
      },
      galleryUrlBackgroundLarge: function() {
        if(this.imageUrl){
          var url = this.imageUrl;
          var stylz = {
            backgroundImage: `url(${url})`,
            backgroundSize: this.lensBgSize,
            backgroundPosition: `${this.bgXpercent}% ${this.bgYpercent}%`,
            backgroundRepeat: 'no-repeat',
          }
          // console.log("stylz", stylz)
          return stylz
        } else {
          return{
            backgroundImage: 'url(https://cloud.bartonandgray.com/library/ux/spectre-chart.jpg)',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            filter: 'blur(1em)', /* ', opacity(0.5)', */
          }
        }
      },
      lensStyleHandler: function(){
        var select_opacity = 1;
        //Lens should NOT be displayed
        if(this.lensState.Display == false){
          select_opacity = 0;
        }

        var styles = {
          opacity: select_opacity,
          top: `${this.lensState.Top}px`,
          left: `${this.lensState.Left}px`
        }
        return styles;
      }
    },
    methods: {
      computeLensBackground(event) {
        var debugLog = false;
        var container = document.getElementById('bcge-chart-image-holder');

        // var clientWidth = container.clientWidth;
        // console.log("Event is", event);
        
        var lensScaleFactor = 2.0;  // e.g. 4.0 creates a 4x lens
        var lensSizePortionOfWidth = 0.25; // match to the "vw" value in css
        
        var lensBackgroundFactor = (1 / lensSizePortionOfWidth) * lensScaleFactor;
        
        
        this.lensBgSize = `${lensBackgroundFactor * 100}%`;
        
        
        var clientRect = container.getBoundingClientRect();
        var clientWidth = clientRect.width;
        var clientHeight = clientRect.height;
        
        
        var clientX = 0
        var clientY = 0
        if (event.touches) {
          var t = event.touches[0];
          clientX = t.clientX;
          clientY = t.clientY;
          
          
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }
        var xOffset = clientX - clientRect.left;
        var yOffset = clientY - clientRect.top;
        
        var xCenterPos = xOffset; //(clientRect.width / 2) - xOffset;
        var yCenterPos = yOffset; //(clientRect.height / 2) - yOffset;
        
        
        // var percentFactor = ((lensScaleFactor - 1) * 100);
        
        
        var clientWidthScaledForLens = clientWidth    // (1 + lensSizePortionOfWidth);
        var clientHeightScaledForLens = clientHeight  // (1 + lensSizePortionOfWidth);
        
        var xPct = (xOffset / clientWidthScaledForLens * 100);
        var yPct = (yOffset / clientHeightScaledForLens * 100);
        
        // re-set  based on lens factor
        xPct = ((xPct - 50) * (1 + lensSizePortionOfWidth)) + (50)
        yPct = ((yPct - 50) * (1 + lensSizePortionOfWidth)) + (50)
        
        var clampPct = function(x) {
          return x
          if (x < 0) {
            return 0
          }
          if (x > 100) {
            return 100
          }
          return x
        }
        // don't clamp; otherwise the lens can't go off-edge
        // xPct = clampPct(xPct);
        // yPct = clampPct(yPct);

        
        if (debugLog) {
          console.log(JSON.stringify({
            w: clientWidth,
            h: clientHeight,
            xOffset: xOffset,
            yOffset: yOffset,
            xCenterPos: xCenterPos,
            yCenterPos: yCenterPos,
            xPct: xPct,
            yPct: yPct,
          }))

        }
        this.bgXpercent = xPct;
        this.bgYpercent = yPct;
        //console.log('bgmousemove', event);
      },
      bgMouseMove(event) {

        this.computeLensBackground(event);
         
        //Lens movement
        this.lensState.Left = event.clientX;
        this.lensState.Top = event.clientY;
        //console.log('BgGalleryLoupe bgmousemove', event);
        
        //Marks the lense as visible again
        this.lensState.Display = true;

        var scaleFactor = 2.75;

        //Magnified image
        let original = document.getElementById("bcge-chart-image-holder"),
           magnified = document.getElementById("large-img"),
           style = magnified.style,
           x = event.pageX - this.offsetLeft,
           y = event.pageY - this.offsetTop,
           imgWidth = original.width,
           imgHeight = original.height,
           xperc = (x / imgWidth) * 100,
           yperc = (y / imgHeight) * 100;

        //Add some margin for right edge
        if(x > 0.1 * imgWidth){
          xperc += 0.15 * xperc;
        }

        //Add some margin for bottom edge
        if(y >= 0.01 * imgHeight){
          yperc += 0.15 * yperc;
        }

        //set Background of magnified image horizontal/vertical
        style.backgroundPositionX = xperc - 9 + "%";
        style.backgroundPositionY = yperc - 9 + "%";

        // this.bgSize = `${scaleFactor * 100}%`;

        //Move the magnifying glass with the mouse movement
        style.left = x - 50 + "px";
        style.top = y - 50 + "px";

      },
      bgMouseLeave(event) {
        // NB!!: Lens has pointer-events: none so that it is not considered for mouseLeave
        
        // if (this.logCount < 100) {
        //  this.logCount += 1;
        //  console.log('BgGalleryLoupe bgmouseleave2', event);
        // }
        this.lensBgSize = '100%';
        this.bgXpercent = 50;
        this.bgYpercent = 50;

        //Hides lens on mouse leave (sets display: none;)
        this.lensState.Display = false;
        
      },
      monitor() {
      },
      go(offset) {
        this.$store.commit('moveGallery', {offset: offset})
      },
      dismiss() {
        this.active = false;
        console.log("BgGalleryLoupe dismissing chart experience");
        this.$store.commit('closeGallery', {});
      },
      externalDismiss() {
        // called from window env outside vue app
        this.dismiss()
      },
      switchToIndex(index) {
        this.index = index;
        console.log("BgGalleryLoupe switchToIndex...", index)
        if ((index != null) && (index >= 0)) {
          console.log("BgGalleryLoupe switchToIndex... A", this.galleryImageUrls)
          if (this.galleryImageUrls) {
            console.log("BgGalleryLoupe switchToIndex... B")
            this.imageUrl = this.galleryImageUrls[this.index];
          }
          this.active = true;
        } else {
          console.log("BgGalleryLoupe switchToIndex... C")
          this.active = false;
        }
      }
    },
    components: {
    },
    created() {
      window.bgGallery = {
        component: this
      }
      
      console.log("BgGalleryLoupe: bg-gallery component created");
      
      this.$store.watch((state) => {
        var r = state.galleryImageUrls;
        console.log("BgGalleryLoupe !!*!*!*!*!*! galleryImageUrls store changed", r)
        this.galleryImageUrls = r
        return r;
      
      })
      this.$store.watch((state) => {
        var r = state.galleryIndex;
        console.log("BgGalleryLoupe.created watched value", r);
        return r;
      }, () => {
        var index = this.$store.state.galleryIndex;
        console.log("BgGalleryLoupe.created galleryIndex changed index", index);
        console.log("BgGalleryLoupe Created with galleryIndex", index);

        this.switchToIndex(index);
      })
    },
    beforeMount() {
      // this.$store.commit('selectGalleryKey', {key: 'boston'});
    }
    
}
</script>
  
