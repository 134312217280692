<!--
  Author: dhersey
  props: 
    :region= String - location thats used in data endpoint
    :useCustomData= boolean - hooks to backend
  notes:
    TO SHIFT TO SATURDAY -> FRIDAY:
      Swap weekdays around
      Change shiftGrid value to compensate
    Four endpoints:
      sailing-cat-the-caribbean
      sailing-cat-gulf-of-maine
      sailing-cat-long-island-and-nantucket-sounds
      sailing-cat-bahamas

      <div class="inquiry-checkout dropdown" v-if="isConflict">
      <span class="start-date-label conflict">Unavailable Selection</span>
      <button class="checkout-button active" @click="clearSelectedDays">CLEAR SELECTION</button>
    </div>
-->
<template>
  <div v-if="isDebug">
    <button @click="debug()">Test</button>
    {{ isConflict }}
  </div>
  <div class="checkout-wrapper" v-bind:class="{'is_active': userSelectedDays.length > 0}">
    <div class="inquiry-checkout">
      <span class="start-date-label" v-if="!isConflict">Start:</span>
      <div class="start-date" v-if="!isConflict">{{ userSelectedDays.length > 0 ? epochToDate(userSelectedDays[0]) : '' }}</div>
      <span class="end-date-label" v-if="!isConflict">End:</span>
      <div class="end-date" v-if="!isConflict">{{ userSelectedDays.length > 0 ? epochToDate(userSelectedDays[userSelectedDays.length - 1]) : '' }}</div>
      <div class="conflict-message" v-if="isConflict">Invalid Selection</div>
      <button class="checkout-button" :class="checkoutButtonStyler()" @click="checkoutMailTo()">{{ buttonState }}</button>
    </div>
    <div class="inquiry-checkout price" v-if="!isConflict && !userSelectedDays.length == 0">
      <span class="start-date-label">Total Price:</span>
      <div class="start-date">{{ getPrice() }}</div>
    </div>
  </div>
  <div class="calendar-container" v-for="(month, count) in cal_months" v-bind:key="month">
    <div class="calendar">
      <div class="cal-header">
        <div class="currentMonth_Year">
          {{ month }}
        </div>
      </div>
      <div class="cal-body">
        <div class="weekdays">
          <div class="weekday" v-for="weekday in weekdays" v-bind:key="weekday">{{ weekday }}</div>
        </div>
        <div class="days">
          <!-- accounts for shift -->
          <div class="day shift" v-for="shift in firstDayArray[count]" v-bind:key="shift"></div>
          <div class="day" v-for="(element, index) in lastDayArray[count]" v-bind:key="element" 
            :class="dayStyleHandler(month, index + 1)"
            @click="e => selectDay(month, index + 1, e)"
            @mouseenter="e => e.target.classList.add('a')"
            @mouseleave="e => e.target.classList.remove('a')">
            <span class="cal-number">{{ index + 1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="v-small-gutter"></div>
  </div>
</template>
<script>
import moment from 'src/OpenSource/moment.min'
// let m = moment();
// console.log("MOMENT MOMENT", m);
export default {
  data() {
    return {
      weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      cal_months: [],
      lastDayArray: [],
      firstDayArray: [],
      selectSchedule: [],
      transitDays: [],
      events: [],
      isFetching: false,
      userSelectedDays: [],
      isConflict: false,
      buttonState: "SELECT START DAY",
      isDebug: true
    }
  },
  props:{
    region: {
      type: String,
      required: true
    },
    // Props using camelCase need to be separated by '-'
    useCustomData: {
      type: Boolean,
      required: false
    }
  },
  methods: { 
    isInRange(low, high, x){
      return((x - high) * (x - low) <= 0);
    },
    dateToTime(date_str){
      return moment(date_str, "YYYY-MM-DD").valueOf(); 
    },
    epochToDate(time_epoch){
      return moment(time_epoch).format("MM/DD/YYYY");
    },
    getTransitDays(){
      for(let i = 0; i < this.events.length; i++){
        // Available events do not have transit days
        if (!this.events[i].available) {
          let day_before = moment(this.events[i].first_day).subtract(1, 'days').valueOf();
          this.transitDays.push(day_before);
          let day_after = moment(this.events[i].last_day).add(1, 'days').valueOf();
          this.transitDays.push(day_after); 
        }
      }
    },
    customDateFormatter(month, day){
      // converts month day to unix number
      // ex input: "January 2023", 1
      // month: String, day: number
      // ex output: 1675746000000
      let day_num = month +' '+ day;
      day_num = this.dateToTime(moment(day_num, "MMMM YYYY DD").format("YYYY-MM-DD"));
      return day_num;
    },
    monthToNum(month_name){
      // Converts month to its corresponding number
      // ex input: "January"
      // output: "01"
      let month_num = moment().month(month_name).format("M");
      if (month_num < 10) {
        month_num = "0" + month_num;
      }
      return month_num.toString();
    },
    dayToNum(day){
      // Converts day to weekday shift
      // ex. input: 'SUN'
      // output: 0
      let day_num;
      for (let index = 0; index < this.weekdays.length; index++) {
        if(this.weekdays[index] == day){
          day_num = index;
        }
      }
      return day_num;
    },
    generateSelectMonths(schedule){
      // Gets all months in current schedule
      // schedule: object
      var month1, month2;
      for(var i = 0; i < schedule.event_dates.length; i++){
        // get event start date month year
        month1 = moment(schedule.event_dates[i].first_day, "YYYY-MM-DD").format('MMMM YYYY');
        // get event end date month year
        month2 = moment(schedule.event_dates[i].last_day, "YYYY-MM-DD").format('MMMM YYYY');
        // Add to cal_months[] 
        // if they aren't already included
        if (!this.cal_months.includes(month1)) {
          this.cal_months.push(month1)
        }
        if (!this.cal_months.includes(month2)) {
          this.cal_months.push(month2)
        }
      }
    },
    generateStartEndOfCalMonths(){
      // Determines what weekday month starts/ends on
      var cal_month, first_day;
      for(var i = 0; i < this.cal_months.length; i++){
        let str_arr = this.cal_months[i].split(" ");
        cal_month = this.monthToNum(str_arr[0]);

        // last day
        this.lastDayArray.push(moment(str_arr[1] +'-'+ cal_month).daysInMonth());

        // first day
        first_day = moment(str_arr[1] +'-'+ cal_month +'-01');
        first_day = first_day.toString().split(' ');
        first_day = this.dayToNum(first_day[0]);
        this.firstDayArray.push(first_day);
      }
    },
    checkoutButtonStyler(){
      if (this.userSelectedDays.length > 0 && this.isConflict != true) {
        if(this.userSelectedDays.length == 1){
          this.buttonState = "SELECT END DAY";
        } else {
          this.buttonState = "INQUIRE";
          return 'inquire'
        }
        return 'active';
      } else if (this.isConflict == true) {
        this.buttonState = "CLEAR SELECTION";
        return 'conflict';
      } else {
        this.buttonState = "SELECT START DAY";
        return
      }
    },
    checkoutMailTo(){
      if(!this.isConflict){
        let subject = this.selectSchedule.label + ' inquiry ' + this.epochToDate(this.userSelectedDays[0]) + ' - ' + this.epochToDate(this.userSelectedDays[1]);
        window.location.href = 'mailto:info@bartonandgray.com?subject='+ encodeURIComponent(subject);
      } else {
        this.clearSelectedDays();
      }
      
    },  
    dayStyleHandler(month, day){
      // ex. input: "January 2023", 24
      // where month = String
      // and day = Number

      // default class name will be available (V2 change)
      var cname = 'available';
      let conflictChecker;

      var date_check = this.customDateFormatter(month, day);

      if(this.isInRange(this.userSelectedDays[0], this.userSelectedDays[this.userSelectedDays.length - 1], date_check)){
        cname = 'selected';
        conflictChecker = 1;
      }

      // Check for transit day or featured event day
      for(let index = 0; index < this.events.length; index++) {
        if(this.transitDays.includes(date_check)){
          cname = 'transit';
          if (conflictChecker == 1) {
            this.isConflict = true;
          }
        }
        // in-between event bounds
        if(this.isInRange(this.dateToTime(this.events[index].first_day), this.dateToTime(this.events[index].last_day), date_check)){
          if(!this.events[index].available){
            cname = 'reserved';
          }
          
        }
      }
      
      return cname;
    },
    populateEvents(){
      // reads thru selectSchedule and adds startDates + endDates to their corresponding arrays
      for (const event_date of this.selectSchedule.event_dates) {
        this.events.push(event_date)
      }
      this.getTransitDays();
    },
    checkAvailability(month, day){
      // Checks availability given month: (String), day: (Number)
      let isAvailable = true;
      var date_check = this.customDateFormatter(month, day);
      for(let index = 0; index < this.events.length; index++) {
        if(this.transitDays.includes(date_check)){
          isAvailable = false;
        }

        if(this.isInRange(this.dateToTime(this.events[index].first_day), this.dateToTime(this.events[index].last_day), date_check)){
          if(!this.events[index].available){
            isAvailable = false;
          }
        }
      }
      return isAvailable;
    },
    mailtoSetup(month, day){
      // Set up mailto: hyperlink
      let date_label = '';
      for(let index = 0; index < this.events.length; index++) {
        if(this.isInRange(this.dateToTime(this.events[index].first_day), this.dateToTime(this.events[index].last_day))){
          if (this.events[index].available) {
            date_label = this.selectSchedule.event_dates[index].time_label;
          } 
        }
      }
      if (this.checkAvailability(month, day)) {
        let subject = 'Andromeda inquiry, '+ this.selectSchedule.label +', '+ date_label;
        window.location.href = 'mailto:info@bartonandgray.com?subject='+ encodeURIComponent(subject);
      }
    },
    selectDay(month, day, e){
      if(this.checkAvailability(month, day)){
        let dayObj = month +' '+ day;
        //dayObj = moment(dayObj, "MMMM YYYY DD").format("MM/DD/YYYY");
        dayObj = moment(dayObj, "MMMM YYYY DD").valueOf();
        if (this.userSelectedDays.length >= 2) {
          // remove selection
          // YOU CAN ONLY CLICK TWICE
          this.clearSelectedDays();
          e.target.classList.remove('selected');
        } else if(!this.userSelectedDays.includes(dayObj)) {
          // add selection
          this.userSelectedDays.push(dayObj);
          e.target.classList.add('selected');
        }
      } else {
        console.log("NOT Available");
        alert("Not available, please choose another day")
      }
      // sorts array
      this.userSelectedDays.sort(function(a,b){return a - b});
    },
    clearSelectedDays(){
      this.userSelectedDays = [];
      this.isConflict = false;
    },
    getTotalDaysSelected(){
      if (this.userSelectedDays.length == 1) {
        return 1;
      } else {
        // since we just need the amount of days selected, we subtract the later date by the earlier date
        // and then divide the remainder by 86400000 which represents a day
        // and then add one, because we lost one of the two days in this.userSelectedDays
        return (this.userSelectedDays[1] - this.userSelectedDays[0]) / 86400000 + 1;
      }
    },
    getPrice(){
      let lowest_rate = 9750;
      let highest_rate = 12750;
      if (this.userSelectedDays.length == 0) {
        return '';
      } else {
        let total_days = this.getTotalDaysSelected();
        let low_rate_formatted = (lowest_rate * total_days).toLocaleString("en-US", {style:"currency", currency:"USD"});
        let high_rate_formatted = (highest_rate * total_days).toLocaleString("en-US", {style:"currency", currency:"USD"})
        return  low_rate_formatted + ' - ' + high_rate_formatted;
      }
    },
    async getTestData(){
      // Adds test data and saves to this.test_data
      // http://www.bartonandgray.localhost:5555/events/sailing-cat-summer-2023/2023/sailing-cat
      //let url = '/events/sailing-cat-bahamas/2023/sailing-cat';
      let url = '/events/sailing-cat-summer-2023/2023/sailing-cat';
      this.isFetching = true;
      try {
        let response = await fetch(url, {
          method: 'GET',
          headers:{
            'Content-Type': 'application/json'
          }
        });
        let finalRes = await response.json();
        this.selectSchedule = finalRes;
        if(this.isDebug){
          console.log('finalRes: ', finalRes);
        }
      } catch(error){
        console.log("Error: ", error);
      } finally{
        this.isFetching = false;
        this.generateSelectMonths(this.selectSchedule);
        this.generateStartEndOfCalMonths();
        this.populateEvents();
      }
    },
    debug(){
      console.log(this.events);
      console.log(this.dateToTime("2023-07-01"));
      console.log(this.userSelectedDays);
    }
  },
  mounted() {
    this.getTestData();
  },
}
</script>
<style lang="scss" scoped>
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/colors';
@use 'stylesheets-bridge/animations';
/* Loses calendar container padding at about 380px */
$available: #D6F6E1;
$reserved: #FAC8C8;
$event: #fceac3;
$selected: #dbd6f6;
$text: #3B648A;
$default: #D9D9D9;
$tidepool: #87BBD9;
$gold: #FFD94A;
.calendar{
  display: inline-grid;
  justify-content: center;
  align-items: center;
  .cal-header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    .year{
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .cal-body{
    .weekdays{
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(7, 1fr);
      color: $text;
      font-weight: 600;
      margin-bottom: 15px;
      text-align: center;
      span{
        width: 50px;
        justify-self: center;
        align-self: center;
        text-align: center;
      }
    }
  }
  @include breaks.bg-breakpoint('very-narrow'){
    /* Prevent the tooltip from overflowing off the page on mobile */
    overflow: hidden;
  }
}
.days{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.day{
  cursor: pointer;
  outline: 0;
  background: transparent;
  background-color: $default;
  color: $text;
  font-size: 16px;
  justify-self: center;
  align-self: center;
  text-align: center;
  display: flex; /* center text inside */
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 2px;
  transition-duration: .2s;
  /* Weekday shift */
  &.shift{
    opacity: 0;
    user-select: none;
    cursor: auto;
  }
  &:hover{
    filter: brightness(90%);
  }
  /* COLORING */
  &.available{
    background-color: $available;
  }
  &.reserved{
    background-color: $reserved;
  }
  &.featured{
    background-color: $event;
  }
  &.selected{
    background-color: $selected;
    transition: 0.2s ease-in-out;
  }
  &.transit{
    background-color: #fceac3;
  }
}

.cal-number{
  // Make "click-throughable"
  user-select: none;
  pointer-events: none;
}
.currentMonth_Year{
  letter-spacing: 0.16em;
  font-weight: 350;
}

.checkout-wrapper{
  margin: 2em 0;
  width: 100%;
  animation: fadeIn ease-in-out .25s;
  transition: 0.2s ease-in-out;
  @media only screen and (max-width: 480px){
    display: none;
    width: 100vw;
  }
  &.is_active{
    @media only screen and (max-width: 480px){
      display: block;
      position: fixed !important;
      top: 18px;
      left: 0;
    }
  }
}

.inquiry-checkout{
  position: relative;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  background-color: $tidepool;
  width: 100%;
  height: 3em;
  border-radius: 8px;
  padding: 0.5em;
  z-index: 2;
  &.price{
    //align-items: center;
    justify-content: start;
    .start-date-label{
      width: 6em;
      @media only screen and (max-width: 800px){
        width: 8em !important;
      }
    }
    .start-date{
      width: 100%;
      justify-content: start;
    }
  }
  .start-date-label{
    &.conflict{
      color: #e63333;
    }
  }
  .start-date, .end-date{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5em;
    width: 6em;
    height: 2em;
    border-radius: 5px;
    //border: 1px solid gray;
  }

  .checkout-button{
    padding: .5em 1em;
    background-color: $default;
    border-radius: 5px;
    border-style: solid;
    color: gray;
    transition: .25s ease-in-out;
    margin-left: auto;
    &.active, &.inquire{
      background-color: $gold;
      color: #324954;
      border-color: #4a8ac9;
    }
    &.conflict{
      background-color: #e6e7e8;
      color: #d14d4d;
      border-color: #d14d4d;
    }
    @media only screen and (max-width: 1280px){
      padding: .5em 0.25em;
    }
    @media only screen and (max-width: 450px){
      padding: 0.15em 0;
      &.inquire{
        padding: 0.5em;
      }
    }
  }
  
  &.dropdown{
    border-radius: 0 0 8px 8px;
    animation-name: dropDown;
    animation-duration: 0.85s;
    top: -6%;
    z-index: 1;
  }
}

.clear-button{
  margin-bottom: 2em;
}

.conflict-message{
  margin: 0 2em;
  color: #d14d4d;
}
</style>