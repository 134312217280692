<template>
  <div>
    <div class="iform-header">INQUIRY FORM</div>

    <div class="inquiry-item">
      <div class="inquiry-item-header">YOUR NAME</div>
      <input class="inquiry-text-input" v-model="name">
    </div>

    <div class="inquiry-item">
      <div class="inquiry-item-header">EMAIL ADDRESS</div>
      <input class="inquiry-text-input" v-model="email">
    </div>

    <div class="inquiry-item">
      <div class="inquiry-item-header">PHONE NUMBER</div>
      <input class="inquiry-text-input" v-model="phone">
    </div>

    <div class="inquiry-item">
      <div class="inquiry-item-header">HARBORS OF INTEREST</div>
      
      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="new-england" value="New England"><span>NEW ENGLAND</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="ny-ct" value="New York"><span>NEW YORK &AMP; CONNECTICUT</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="mid-atlantic" value="Mid Atlantic"><span>MID ATLANTIC</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="low-country" value="Low Country"><span>LOW COUNTRY</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="great-lakes" value="The Great Lakes"><span>THE GREAT LAKES</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="florida" value="Florida"><span>FLORIDA</span>
      </div>

      <div class="harbor-input-item">
        <input v-model="checked_harbors" type="checkbox" id="west-indies" value="West Indies"><span>THE WEST INDIES</span>
      </div>
    </div>

    <div class="inquiry-item">
      <div class="inquiry-item-header">YACHTING INTERESTS</div>

      isDropdownActive {{ isDropdownActive }}
      <div class="iform-dropdown-wrapper" @click="toggleInterestDropdown()">
        <div class="iform-dropdown-header">PRIMARILY INTERESTED IN {{ primaryInterestText }}</div>
        <div class="iform-dropdown" :class="{ active: isDropdownActive }">
          <span @click="changeInterest('Dayboating')">Dayboating</span>
          <span @click="changeInterest('Adventure Cruising')">Adventure Cruising</span>
          <span @click="changeInterest('Cuisine')">Cuisine</span>
        </div>
      </div>
    </div>

    <button @click="test()">What data do i have?</button>

  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const name = ref([]);
  const email = ref([]);
  const phone = ref([]);
  const checked_harbors = ref([]);

  let isDropdownActive = ref(false)
  function toggleInterestDropdown(){
    console.log(isDropdownActive.value);
    
    isDropdownActive.value = !isDropdownActive.value;
  }

  let primaryInterestText = ref('...')
  function changeInterest(text_value){
    console.log(text_value);
    
    primaryInterestText.value = text_value;
    console.log('toggling');
    
    toggleInterestDropdown;
  }

  function test(){
    console.log(name.value + '\n' + email.value + '\n' + phone.value + '\n' + checked_harbors.value);
  }

</script>

<style scoped lang="scss">

  .iform-header{
    color: #324954;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    letter-spacing: 1.28px;
  }

  .inquiry-item{
    margin: 2em 0;
    .inquiry-text-input{
      width: 600px;
      max-width: 100%;
      height: 50px;
      
      font-size: 1.5em;
      color: #324954;
    }
  }

  .iform-dropdown-wrapper{
    border: 1px solid grey;
    width: 600px;
    max-width: 100%;
    .iform-dropdown-header{
      &:hover{
        transition: 0.15s ease-in-out;
        background-color: grey;
        cursor: pointer;
      }
    }
    .iform-dropdown{
      display: none;
      &.active{
        display: flex;
        flex-direction: column;
      }
      span{
        &:hover{
          transition: 0.15s ease-in-out;
          background-color: grey;
          cursor: pointer;
        }
      }
    }
  }

</style>