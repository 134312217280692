<template>
  <section class="bg-inq">
    <h2 v-show="submitted">We received your information. Thank you!</h2>

    <form v-show="! submitted">

      <p class="req"><span class="req-star">*</span> Required</p>


      <div class="inq-name-wrapper">
        <div class="inq-item">
          <div>First Name <span class="req-star">*</span></div>
          <input type="text" v-model="inq.first_name" name="name" placeholder="first name" />
        </div>
        <div class="inq-item">
          <div>Last Name <span class="req-star">*</span></div>
          <input type="text" v-model="inq.last_name" name="name" placeholder="last name" />
        </div>
      </div>


      <div class="inq-item">
        <div>Email <span class="req-star">*</span></div>
        <input type="text" v-model="inq.email" name="email" placeholder="e-mail address" />
      </div>
  

      <div class="inq-item">
        <div>Phone Number</div>
        <input type="text" v-model="inq.phone" name="phone_number" placeholder="best number to reach you" />
      </div>

      <div><em>By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out of further messaging.</em></div>

      <div class="inq-item" v-if="askHarbors">
        <div>Harbors of Interest <span class="req-star">*</span></div>
        <section class="multi-choice">
          <div v-for="p in ports" v-bind:key="p">
            <label><input type="checkbox" v-model="inq.ports[p.key]"> 
              {{p.label}}
            </label>
          </div>
        </section>
      </div>


      <div v-if="yachtworks" class="inq-item">
        <div>Area of Interest</div>
        <section class="xxx-multi-choice">
          <div>
            <label>
              <input type="radio" v-model="inq.yw_interest" value="own_daychaser_48"> 
              Ownership/Fleet Ownership of B&G Daychaser 48’
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.yw_interest" value="club_membership"> 
              Membership with Barton & Gray Mariners Club
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.yw_interest" value="fleet_own_talaria_coi"> 
              Fleet Ownership of Hinckley Talaria COI
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.yw_interest" value="careers"> 
              Careers at Barton & Gray
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.yw_interest" value="general"> 
              General Inquiry
            </label>
          </div>
        </section>
        
      </div>
      
      <div class="inq-item" v-if="askHarbors">
        <div>Which products are you interested in?</div>
        <section class="multi-choice">

          <div>
            <label>
              <input type="checkbox" v-model="inq.products_map.dayboating"> Dayboating
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" v-model="inq.products_map.events"> Events
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" v-model="inq.products_map.cruising"> Adventure Sailing Charters
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" v-model="inq.products_map.sportfishing"> Sportfishing
            </label>
          </div>

        </section>
      </div>
      
      <div class="inq-item">
        <div>Comments, Questions, Sea Shanty's</div>
        <textarea msd-elastic v-model="inq.comments" style="width: 100%; height: 4em" placeholder="anything  at all">
        </textarea>
      </div>


      <div class="inq-item">
        <div>How did you hear about Barton &amp; Gray?</div>
        <section class="multi-choice">
          <div>
            <label>
              <input type="radio" v-model="inq.how_heard" value="member"> From a B&amp;G Member
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.how_heard" value="friend"> From a friend
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.how_heard" value="print-media"> Newspaper / Magazine
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.how_heard" value="club"> Club / Resort
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="inq.how_heard" value="online"> Blog, Google, Website
            </label>
          </div>
        </section>
      </div>



      <div><span class="inq-submit bg-hover medium lighten-125" v-on:click="submitClicked()">SUBMIT</span></div>


    </form>


  </section>
</template>



<style lang="scss">
.bg-inq {
  $navy-blue: #324954; /* (50,73,84). */
  $req-red: #f22;
  .inq-submit {
    cursor: pointer;
    background-color: $navy-blue;
    color: white;
    border-radius: 0.2em;
    font-size: 1.2em;
    padding: 0.2em 0.6em;
  }
  .inq-item {
    margin: 1.5em 0;
  }
  label {
    display: flex;
    align-items: center;
    margin-bottom: 0.25em;
    input {
      margin-right: 0.6em;
    }
  }
  .multi-choice {
    margin-left: 0.75em;
    column-count: 2;
  }
  .req {
    color: $req-red;
  }
  .req-star {
    color: $req-red;
    font-weight: bold;
  }
  ::placeholder {

  }
  textarea {
    color: $navy-blue;
    font-size: 1.1em;
    border-radius: 0.2em;
  }
  input[type=text] {
    color: $navy-blue;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    font-size: 1.25em;
    border-radius: 0.2em;
    padding: 0.35em;
    width: 100%;
  }
  textarea {
    padding: 0.15em;
    width: 100%;
  }
}

.inq-name-wrapper{
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: 1280px){
    flex-direction: row;
    .inq-item{
      &:last-child{
        margin-left: 1.5em;
      }
    }
  }
}
</style>



<script>
  
import { inquireService }  from 'src/Inquiries/InquireService'
import { portService } from 'src/Realm/Harbors/PortService'
import { doOnceService } from 'src/Mwk/DoOnceService'
  

export default {
  props: {
  	askHarbors: {
      type: Boolean,
  	},
    sourceCode: {
      type: String,
    },
    yachtworks: {
      type: Boolean
    }
  },
    data() {
      return {
        inq: {
          ports: {},
          products_map: {},
        },
        ports: [],
        submitted: false
      }
    },
    methods: {
      monitor() {
        console.log('INQ is', this.inq)
      },
      submitClicked() {
        if (! this.askHarbors) {
          this.inq.notAskingHarbors = true
        }
        if (this.yachtworks) {
          this.inq.property = 'yachtworks'
        }

        this.inq.source_code = this.sourceCode;
        
        if (! inquireService.valid(this.inq)) {
          alert("Please enter your name, email, and harbors of interest")
        } else {
          this.submit()
        }

      },
      submit() {
        doOnceService.doOnce('inquirySubmit', this.submitImpl)
      },
      submitImpl() {
        var promise = new Promise((resolve, reject) => {
          inquireService.submit(this.inq).then(() => {
            this.submitted = true;
            resolve();
          }).catch((err) => {
            console.log("SubmitImpl BGInquiry Got err in caller", err)
            reject();
          })
          
        })
        return promise;

        
      },
      loadPorts() {
        console.log("this.askHarbors", this.askHarbors);
        if (this.askHarbors) {
          portService.listForInquiry().then((ports) => {
            console.log("Got ports", ports);
            this.ports = ports;
          })
        }
        
      }
      
    },
    components: {
    },
    beforeMount() {
      this.loadPorts()
    }
    
}

</script>
  
