<!--

  Date: 11/15/22
  Auth: dhersey
  Desc:
    This component is mainly the 'mobile' version of BgExperienceCarousel
    It just displays every experience in one of the two current season
      data files. winterExps.json / summerExps.json
    The data file should be 20 experiences and for best display follow
      the order: [M, m, m, M, M, m, m, ...]

  Props:
    season: Which season data file you want to display ("'winter'" for ex)
      defaults to "'summer'"

  Notes:
    -Index starts at 1 so it doesnt include first 4 events (because they're shown in main page)
    -if you want to include first 4 events, use (index - 1)

-->

<template>
  <div>
    <div v-for="index in 4" v-bind:key="index">
      <section class="bg-scene">
        <!-- MAX 1 -->
        <section class="bg-scene-2-3col">
          <div class="v-spacer"></div>
          <div class="bg-image-holder-fixed bg-ratio-660-298">
            <img v-bind:src='experiences[index + (index * 3)].img_src'>
          </div>
          <div class="v-small-gutter"></div>
          <div class="bg-headline">
            {{ experiences[index + (index * 3)].title }}
          </div>
        </section>
        <!-- MINI 1 -->
        <section class="bg-scene-3col">
          <div class="v-spacer"></div>
          <div class="bg-image-holder-fixed bg-ratio-1">
            <img v-bind:src='experiences[(index + 1) + (index * 3)].img_src'>
          </div>
          <div class="v-small-gutter"></div>
          <div class="bg-headline">
            {{ experiences[(index + 1) + (index * 3)].title }}
          </div>
        </section>
      </section>

      <section class="bg-scene">
        <!-- MINI 2 -->
        <section class="bg-scene-3col">
          <div class="v-spacer"></div>
          <div class="bg-image-holder-fixed bg-ratio-1">
            <img v-bind:src='experiences[(index + 2) + (index * 3)].img_src'>
          </div>
          <div class="v-small-gutter"></div>
          <div class="bg-headline">
            {{ experiences[(index + 2) + (index * 3)].title }}
          </div>
        </section>
        <!-- MAX 2 -->
        <section class="bg-scene-2-3col">
          <div class="v-spacer"></div>
          <div class="bg-image-holder-fixed bg-ratio-660-298">
            <img v-bind:src='experiences[(index + 3) + (index * 3)].img_src'>
          </div>
          <div class="v-small-gutter"></div>
          <div class="bg-headline">
            {{ experiences[(index + 3) + (index * 3)].title }}
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import winter_experiences from './pop-experiences/winterExps.json';
import summer_experiences from './pop-experiences/summerExps.json';

export default {
  data() {
    return {
      experiences: summer_experiences,
    }
  },
  props:{
    season:{
      type: String,
      default: 'summer'
    }
  },
  methods: {
    setEvents(x){
      if(x == 'winter'){
        this.experiences = winter_experiences;
      } else {
        this.experiences = summer_experiences;
      }
    }
  },
  mounted() {
    this.setEvents(this.season);
    console.log("this.experiences: ",this.experiences)
  },
}
</script>

<style lang="scss">
  @use 'stylesheets-bridge/www2/breaks';
  @use 'stylesheets-bridge/www2/definitions';
  @use 'stylesheets-bridge/colors';
  @use 'stylesheets-bridge/animations';
</style>