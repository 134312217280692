<!--

  Author: dhersey
  Desc: New Carousel component for V3 galleries

  props: 
    :slide_gallery = The images for the gallery
    :grid_gap = The gap between the images (doesnt include the slideshow)
    :columns = The grid-template-columns setup

  notes: * Uses Vue Composition API rather than Options api *
    - Might want to keep every photo same dimensions but have decently high file size
    - Like 320 x 320 but 1mb?
    - Going to need to play around a bit once i've got the right photos

  <img v-for="image in props.slide_gallery" v-bind:key="image" v-bind:src="image">
-->
<template>
  <div class="gallery-slideshow-wrapper" v-bind:class="{active: isSlideshowActive}">
    <div class="gallery-control">
      <div class="prev">
        <img v-on:click="changeSlide(-1)" src="https://cloud.bartonandgray.com/library/ux/icons/V3/gallery-arrow-prev.png"/>
      </div>
      <div class="gallery-image">
        <img @click="toggleSlideshow()" class="close-control" src="https://cloud.bartonandgray.com/library/ux/icons/V3/close-button.png">
        <img class="active-image" :src="props.slide_gallery[slide_index - 1]"/>
      </div>
      <div class="next">
        <img v-on:click="changeSlide(1)" src="https://cloud.bartonandgray.com/library/ux/icons/V3/gallery-arrow-next.png"/>
      </div>
    </div>
  </div>
  <div class="v3-gallery-wrapper">
    <div class="gallery-image-grid">
      <div class="grid-image" v-for="image, count in props.slide_gallery" v-bind:key="image">
        <img :src="image" @click="toggleSlideshow(count)"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue";

  //props
  const props = defineProps({
    slide_gallery: Array,
    grid_gap:{
      type: String,
      default: "0"
    },
    columns:{
      type: String,
      default: "1"
    },
    thumbnail_size:{
      type: String,
      default: "100%"
    }
  });

  // reactive state
  const slide_index = ref(1);
  const isSlideshowActive = ref(false);
  const total_columns = "repeat(" + props.columns + ", 1fr)";

  function toggleSlideshow(count){
    if(isSlideshowActive.value){
      isSlideshowActive.value = false;
    } else {
      slide_index.value = count + 1;
      isSlideshowActive.value = true;
    }
  }

  // Key controls
  if (isSlideshowActive) {
    window.addEventListener('keyup', handleKeypress);
  } else {
    window.removeEventListener('keyup', handleKeypress);
  }
  
  function handleKeypress(e) {
    switch (e.keyCode) {
      case 39: // right
        changeSlide(1)
        break;
      case 37: // left
        changeSlide(-1)
        break;
      case 27: // esc
        toggleSlideshow();
        break;
      default:
        break;
    }
  }

  function changeSlide(x){
    if (slide_index.value + x <= 0) {
      slide_index.value = props.slide_gallery.length;
    } else if(slide_index.value + x > props.slide_gallery.length){
      slide_index.value = 1;
    } else {
      slide_index.value += x;
    }
  }
</script>
<style lang="scss" scoped>
  .gallery-image-grid{
    display: grid;
    margin: 0 auto;
    grid-template-columns: v-bind(total_columns);
    gap: v-bind(grid_gap);
    // v-bind
    .grid-image{
      transition: 0.15s ease-in-out;
      img{
        height: v-bind(thumbnail_size); // was 100%
        width: v-bind(thumbnail_size); // was 100%
        object-fit: cover;
        @media only screen and (max-width: 800px){
          width: 100%;
        }
      }
      &:hover{
        filter: brightness(90%);
        cursor: pointer;
      }
    }
  }

  /* Should reach 1440px - padding */

  .gallery-slideshow-wrapper{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 1;
    
    &.active{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .gallery-control{
      display: flex;
      gap: 1em;
      justify-content: center;
      width: 100%;
      max-width: 1440px;
      padding: 0 5%;
      .prev, .next{
        display: flex;
        align-items: center;
        img{
          width: 40px;
          height: auto;
          &:hover{
            cursor: pointer;
            transition: 0.2s ease-in-out;
            filter: brightness(85%);
          }
        }
      }
      .gallery-image{
        border: 1px solid #4d4d4f;
        position: relative;
        width: 100%;
        max-width: 500px;
        .close-control{
          position: absolute;
          display: block;
          right: -18px;
          top: -18px;
          z-index: 1;
          width: 40px;
          &:hover{
            cursor: pointer;
            transition: 0.2s ease-in-out;
            filter: brightness(85%);
          }
        }
        .active-image{
          width: 100%;
          height: 100%;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }

</style>