<template>
  <div class="layout-3col" style="">

    <!-- Left -->
    <div class="col-1">
      <!-- Intro -->
      <div style="">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Voyage Summary</div>
        <p class="v4-base-paragraph color-storm">
          The following information has been gathered and the Adventure Cruising Crew will be in touch shortly to continue the conversation. Thanks so much!
        </p>
      </div>

    </div>

    <!-- Middle -->
    <div class="col-2">
      <div style="margin-bottom: 2em;">
        <!-- Destinations -->
        <div class="summary-item">
          <div class="v4-base-header-nova color-gradient-shark">DESTINATIONS</div>
          <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
            <li class="color-storm" v-for="destination in destinationsValue" v-bind:key="destination">{{ destination }}</li>
          </ul>
        </div>

        <!-- Timeframe -->
        <div class="summary-item">
          <div class="v4-base-header-nova color-gradient-shark">TIMEFRAME</div>
          <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
            <li class="color-storm" v-for="date in datesValue" v-bind:key="date">{{ date }}</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="col-3">
      <div style="margin-bottom: 2em;">
        <!-- Activities -->
        <div class="summary-item">
          <div class="v4-base-header-nova color-gradient-shark">ACTIVITIES &AMP; CUISINE</div>
          <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
            <li class="color-storm" v-for="option in optionsValue" v-bind:key="option">{{ option }}</li>
          </ul>
        </div>

        <!-- Contact Info -->
         <div class="summary-item">
            <div class="v4-base-header-nova color-gradient-shark">CONTACT INFORMATION</div>
            <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
              <li class="color-storm" v-for="item in userValue" v-bind:key="item">{{ item }}</li>
            </ul>
         </div>
      </div>
    </div>

  </div>
</template>
  
<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations', 'dates', 'options', 'user']);
  const destinationsValue = ref(props.destinations);
  const datesValue = ref(props.dates);
  const optionsValue = ref(props.options);
  const userValue = ref(props.user);

  watch(() => props.destinations, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    destinationsValue.value = newValue;
  }); 

  watch(() => props.dates, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    datesValue.value = newValue;
  }); 

  watch(() => props.options, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    optionsValue.value = newValue;
  }); 

  watch(() => props.user, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    userValue.value = newValue;
  }); 

</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .summary-item{
    margin-bottom: 2em;
  }

</style>