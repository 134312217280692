<!--

  VueJS component that places a "magnifying glass" effect over
    an image

  Props:
    :src = >Link to image<
    :zoom = >magnifying factor<
    :radius = >Height and width of the lens<

  Note: For some reason, this works really well for .jpg files, 
    whereas for .png's, the image resolution comes out lackluster

-->
<template>
  <div id="bg-magnify-image-holder" class="bg-magnify-image-holder" style="flex-shrink: 0; cursor: none;" 
    v-on:mousemove="bgMouseMove($event)" 
    v-on:mouseout="bgMouseLeave($event)">
    <img :src="magImageUrl" :alt="imageAlt" style="width: 100%; display: block">
    <div id="magnifying-lens-container" class="magnifying-lens-container" style="z-index: 8001; pointer-events: none;" :style="this.lensStyleHandler">
      <div id="large-img" class="lens" :style="this.setUrlBackgroundLarge"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        magImageUrl: this.src, 
        zoomFactor: this.zoom,
        lensRadius: this.radius,
        imageAlt: this.alt_tag,
        isDebug: false,
        bgXpercent: 50,
        bgYpercent: 50,
        lensBgSize: '100%',
        lensState: {
          Left: 0,
          Top: 0,
          Display: false,
        }
      }
    },
    props: {
      src:{
        default: 'https://cloud.bartonandgray.com/library/ux/spectre-chart.jpg',
        type: String
      },  // Source of the image
      zoom:{
        default: 1.5,
        type: Number
      }, // Lens multiplier, ex 4.0 = 4x lens
      radius:{
        default: 15,
        type: Number
      }, //Lens radius (IN EM)
      alt_tag:{
        default: '',
        type: String
      }
    },
    computed: {
      setUrlBackgroundLarge: function(){
        if(this.magImageUrl){
          if(this.isDebug == true){
            console.log("setUrlBackgroundLarge Showing: ", this.magImageUrl);
          }
          return {
            backgroundImage: `url(${this.magImageUrl})`,
            backgroundSize: this.lensBgSize,
            backgroundPosition: `${this.bgXpercent}% ${this.bgYpercent}%`,
            backgroundRepeat: 'no-repeat',
            height: `${this.lensRadius}em`,
            width: `${this.lensRadius}em`,
          }
        } else {
          // If there is no image
          return {
            backgroundImage: 'url(https://cloud.bartonandgray.com/library/ux/spectre-chart.jpg)',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            filter: 'blur(1em)', /* ', opacity(0.5)', */
          }
        }
      },
      lensStyleHandler: function(){
        let select_opacity = 1;

        // Lens should NOT be displayed if Display == false
        if(this.lensState.Display == false){
          select_opacity = 0;
        }

        let styles = {
          opacity: select_opacity,
          top: `${this.lensState.Top}px`,
          left: `${this.lensState.Left}px`,
        }

        return styles;
      }
    },
    methods: {
      computeLensBackground(event) {
        
        // Testing purposes
        let debugLog = false;

        //Zoom magnifying multiplier
        let lensScaleFactor = this.zoomFactor;
        let lensSizePortionOfWidth = 0.25;

        let lensBackgroundFactor = (1 / lensSizePortionOfWidth) * lensScaleFactor;

        this.lensBgSize = `${lensBackgroundFactor * 100}%`;

        // Getting the event.target.getBoundingClientRect is essential in making this component play nice with other
        //  similar components on the same page
        let clientRect = event.target.getBoundingClientRect();
        let clientWidth = clientRect.width;
        let clientHeight = clientRect.height;

        let clientX = 0;
        let clientY = 0;
        if(event.touches){
          let t = event.touches[0];
          clientX = t.clientX;
          clientY = t.clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        let xOffset = clientX - clientRect.left;
        let yOffset = clientY - clientRect.top;

        let xCenterPos = xOffset; //(clientRect.width / 2) - xOffset;
        let yCenterPos = yOffset; //(clientRect.height / 2) - yOffset;

        let clientWidthScaledForLens = clientWidth;
        let clientHeightScaledForLens = clientHeight;

        let xPct = (xOffset / clientWidthScaledForLens * 100);
        let yPct = (yOffset / clientHeightScaledForLens * 100);

        xPct = ((xPct - 50) * (1 + lensSizePortionOfWidth)) + (50)
        yPct = ((yPct - 50) * (1 + lensSizePortionOfWidth)) + (50)

        // Testing purposes
        if(debugLog){
          console.log(JSON.stringify({
            w: clientWidth,
            h: clientHeight,
            xOffset: xOffset,
            yOffset: yOffset,
            xCenterPos: xCenterPos,
            yCenterPos: yCenterPos,
            xPct: xPct,
            yPct: yPct,
          }))
        }

        this.bgXpercent = xPct;
        this.bgYpercent = yPct;
      },
      bgMouseMove(event){
        this.computeLensBackground(event);

        // Lens Movement
        this.lensState.Left = event.clientX;
        this.lensState.Top = event.clientY;

        // Marks the lens as visible again
        this.lensState.Display = true;

        // Magnified image
        // Look for a different way to grab images?
        let original = document.getElementById("bg-magnify-image-holder"),
          magnified = document.getElementById("large-img"),
          style = magnified.style,
          x = event.pageX - this.offsetLeft,
          y = event.pageY - this.offsetTop,
          imgWidth = original.width,
          imgHeight = original.height,
          xperc = (x / imgWidth) * 100,
          yperc = (y / imgHeight) * 100;

        // Add some margin for right edge
        if(x > 0.1 * imgWidth){
          xperc += 0.15 * xperc;
        }

        // Add some margin for bottom edge
        if(y >= 0.01 * imgHeight){
          yperc += 0.15 * yperc;
        }

        // Set background of magnified image horizontal/vertical
        style.backgroundPositionX = xperc - 9 + "%";
        style.backgroundPositionY = yperc - 9 + "%";

        // Move the magnifying glass with the mouse movement 
        style.left = x - 50 + "px";
        style.top = y - 50 + "px";

        if(this.isDebug){
          console.log("Original: ", original);
          console.log("Magnified: ", magnified);
        }
      },
      bgMouseLeave(event){
        this.lensBgSize = '100%';
        this.bgXpercent = 50;
        this.bgYpercent = 50;

        // Hides lens on mouse leave (set opacity: 0)
        this.lensState.Display = false;
      }
    },
    components: {
    },
    beforeMount(){
      // this.$store.commit('selectGalleryKey', {key: 'boston'});
    }
  }
</script>

<style lang="scss">
  @use 'stylesheets-bridge/www2/breaks';
  @use 'stylesheets-bridge/www2/definitions';
  @use 'stylesheets-bridge/colors';
  @use 'stylesheets-bridge/animations';
  
  .magnifying-lens-container{
    position: fixed;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 0.4s ease-in-out;
    left: -100px;
    top: 50%;
    .lens{
      // set height and width to x% the width of the viewport (since this gallery goes edge-to-edge,
      // 20vw e.g. results in a 20% width loupe)
      height: 15em; // was 100px
      width: 15em; // was 100px
      box-shadow: inset 0 0 10px 15px grey;
      z-index: 11000;
      border: 2px solid white;
      border-radius: 50%;
      transition: opacity 0.2s;
    }
    @include breaks.bg-breakpoint('very-narrow'){
      display: none;
    }
  }
</style>