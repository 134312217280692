// ATTRIB: MKHTTP is MWKrom Software

class MKHTTPClass {

  // send as JSON
  // return {data: ... json-parsed data as native objects }
  // require data.status to be 'ok' else flags certain errrors
  post(url, data, opts) {
    console.log("POST inputs", url, data, opts)
    // Default options are marked with *
    var postOpts = this.protoOpts()
    postOpts.method = 'POST'
    postOpts.body = JSON.stringify(data)
    
    var mkRequest = new Request(url, postOpts) 
    var me = this;
    var promise = new Promise(function(resolve, reject) {
      fetch(mkRequest).then(function(response) {
        
        var dataPortion = null;
        response.json().then(function(data) {
          dataPortion = data;
          var ret = {data: dataPortion};
          console.log('get resolving', ret);
          if (data.status == 'ok') {
            resolve(ret);
          } else {
            me.handleError(response, opts)
          }
        }).catch(function(err) {
          me.handleError(response, opts)
          reject(err)
        })
        
      }).catch(function(err) {
        me.handleError(response, opts)
        reject(err);
      })
    
    })
    return promise;
    
  }
  
  handleError(response, opts={}) {
    if (opts.suppressError) {
      return;
    }
    var oe = (opts.error || {})
    var errcode = (oe.code || 'A1')
    var errmsg = (oe.message || 'A problem occurred.')
    alert(`${errmsg} [${errcode}]`)
    
  }

  get(url, opts) {
    console.log("GET inputs", url, opts)

    var getOpts = this.protoOpts()
    getOpts.method = 'GET'

    var mkRequest = new Request(url, getOpts) 
    var me = this;
    var promise = new Promise(function(resolve, reject) {
      fetch(mkRequest).then(function(response) {
        
        var dataPortion = null;
        response.json().then(function(data) {
          dataPortion = data;
          var ret = {data: dataPortion};
          console.log('get resolving', ret);
          if (data.status == 'ok') {
            resolve(ret);
          } else {
            me.handleError(response, opts)
          }
        }).catch(function(err) {
          me.handleError(response, opts)
          reject(err)
        })
        
      }).catch(function(err) {
        me.handleError(response, opts)
        reject(err);
      })
    
    })
    return promise;

  }
  
  
  protoOpts() {
    return ({
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
  }
  
}

var MKHTTP = new MKHTTPClass()

export {MKHTTP}
