<!--
  Author: dhersey
  props: 
    :region= String - location thats used in data endpoint
    :useCustomData= boolean - hooks to backend
  notes:
    TO SHIFT TO SATURDAY -> FRIDAY:
      Swap weekdays around
      Change shiftGrid value to compensate
    Four endpoints:
      sailing-cat-the-caribbean
      sailing-cat-gulf-of-maine
      sailing-cat-long-island-and-nantucket-sounds
      sailing-cat-bahamas
-->
<template>
  <div class="calendar-container" v-for="(month, count) in cal_months" v-bind:key="month">
    <div class="calendar">
      <div class="cal-header">
        <div class="currentMonth_Year">
          {{ month }}
        </div>
      </div>
      <div class="cal-body">
        <div class="weekdays">
          <div class="weekday">SUN</div>
          <div class="weekday">MON</div>
          <div class="weekday">TUE</div>
          <div class="weekday">WED</div>
          <div class="weekday">THU</div>
          <div class="weekday">FRI</div>
          <div class="weekday">SAT</div>
        </div>
        <div class="days">
          <!-- accounts for shift -->
          <div class="day shift" v-for="shift in firstDayArray[count]" v-bind:key="shift"></div>
            <div class="day" v-for="(element, index) in lastDayArray[count]" v-bind:key="element" 
              :class="dayStyleHandler(month, index + 1)"
              @click="e => e.target.classList.toggle('active')"
              @mouseleave="e => e.target.classList.remove('active')">
              <div class="day-tooltip" :class="dayStyleHandler(month, index + 1)">
                <p class="day-tooltip-content" 
                  :class="dayStyleHandler(month, index + 1)" 
                  v-text="tooltipHandler(month, index + 1)" >
                </p>
                <div class="bg-menu" 
                  v-if="checkAvailibility(month, index + 1)"
                  @click="mailtoSetup(month, index + 1)">
                  <label class="on">
                    <div class="bg-menu-label">INQUIRE</div>
                  </label>
                </div>
              </div>    
              <span class="cal-number">{{ index + 1 }}</span>
            </div>
        </div>
      </div>
    </div>
    <div class="v-small-gutter"></div>
  </div>
</template>
<script>
import AndromedaSchedule2023 from './andromeda-calendar-2023.json';
import moment from 'src/OpenSource/moment.min'
// let m = moment();
// console.log("MOMENT MOMENT", m);
export default {
  data() {
    return {
      months: [
        "January","February","March","April","May","June","July",
        "August","September","October","November","December"
      ],
      weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      test_data: {},
      cal_months: [],
      lastDayArray: [],
      firstDayArray: [],
      selectSchedule: [],
      events:{
        startDates:[],
        endDates:[]
      },
      isFetching: true
    }
  },
  props:{
    region: {
      type: String,
      required: true
    },
    // Props using camelCase need to be separated by '-'
    useCustomData: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    selectRegion(){
      // Narrows down data to region
      // this will be deprecated once we connect to backend
      var x;
      switch(this.region){
        case 'abacos':
          x = 0;
          break;
        case 'long-island':
          x = 1;
          break;
        case 'maine':
          x = 2;
          break;
        case 'caribbean':
          x = 3;
          break;
        default:
          x = 0;
          break;
      }
      this.selectSchedule = AndromedaSchedule2023[x];
    },
    isInRange(low, high, x){
      // returns true if x is inbetween low and high
      return((x - high) * (x - low) <= 0);
    },
    dateToTime(date_str, isEvent){
      // converts date_str to time NUMBER
      // ex inputs: "January 27 2022", "2022-01-27"
      // ex output: 1688097600000
      if (isEvent) {
        // format YYYY-MM-DD
        // ie "2022-01-27"
        date_str = moment(date_str, "YYYY-MM-DD");
      } else {
        // format MMMM DD YYYY
        // ie "January 27 2022"
        date_str = moment(date_str, "MMMM DD YYYY");
      }
      let date_num = moment(date_str).valueOf(); // time number
      return date_num;
    },
    customDateFormatter(month, day){
      // converts month day to unix number
      // ex input: "January 2023", 1
      // month: String, day: number
      // ex output: 1675746000000
      if(day < 10){
        day = day.toString();
        day = '0' + day;
      }
      let str_arr = month.split(" ");
      let month_str = str_arr[0];
      let year_str = str_arr[1];
      let date_str = '';
      date_str = date_str.concat(month_str,' ', day, ' ', year_str);
      let date_as_time = this.dateToTime(date_str);
      return date_as_time;
    },
    monthToNum(month){
      // Converts month to its corresponding number
      // ex input: "January"
      // output: "01"
      let month_num;
      for (let index = 0; index < this.months.length; index++) {
        if (this.months[index] == month) {
          month_num = index + 1;
        }
      }
      if (month_num < 10) {
        month_num = "0" + month_num;
      }
      return month_num.toString();
    },
    dayToNum(day){
      // Converts day to weekday shift
      // ex. input: 'SUN'
      // output: 0
      let day_num;
      for (let index = 0; index < this.weekdays.length; index++) {
        if(this.weekdays[index] == day){
          day_num = index;
        }
      }
      return day_num;
    },
    generateSelectMonths(schedule){
      // Gets all months in current schedule
      // schedule: object
      var month1, month2;
      for(var i = 0; i < schedule.event_dates.length; i++){
        // get event start date month year
        var startDate = moment(schedule.event_dates[i].first_day, "YYYY-MM-DD"); // ie 01/07/2023
        month1 = startDate.format('MMMM YYYY');
        // get event end date month year
        var endDate = moment(schedule.event_dates[i].last_day, "YYYY-MM-DD");
        month2 = endDate.format('MMMM YYYY');
        // Add to cal_months[] 
        // if they aren't already included
        if (!this.cal_months.includes(month1)) {
          this.cal_months.push(month1)
        }
        if (!this.cal_months.includes(month2)) {
          this.cal_months.push(month2)
        }
      }
    },
    generateStartEndOfCalMonths(){
      // Determines what weekday month starts/ends on
      var cal_month, last_day, first_day;
      for(var i = 0; i < this.cal_months.length; i++){
        let str_arr = this.cal_months[i].split(" ");
        cal_month = this.monthToNum(str_arr[0]);
        last_day = moment(str_arr[1] +'-'+ cal_month).daysInMonth();
        this.lastDayArray.push(last_day);
        first_day = moment(str_arr[1] +'-'+ cal_month +'-01');
        first_day = first_day.toString().split(' ');
        first_day = this.dayToNum(first_day[0]);
        this.firstDayArray.push(first_day);
      }
    },
    dayStyleHandler(month, day){
      // ex. input: "January 2023", 24
      // where month = String
      // and day = Number
      var cname;
      //Although similar to checkAvailability(),
      // dont want to run that or else it will style 
      var date_check = this.customDateFormatter(month, day);
      for(let index = 0; index < this.events.startDates.length; index++) {
        if(this.isInRange(this.events.startDates[index], this.events.endDates[index], date_check)){
          // maybe switch here, will refactor 
          if (!this.selectSchedule.event_dates[index].available) {
            //if reason maybe show that?
            cname = 'reserved';
          } else{ // is available
            if (this.selectSchedule.event_dates[index].isEvent) {
              cname = 'available event'
            } else {
              cname = 'available';
            }
          }
        }
      }
      return cname;
    },
    populateEvents(){
      // reads thru selectSchedule and adds startDates + endDates to their corresponding arrays
      for(let index = 0; index < this.selectSchedule.event_dates.length; index++) {    
        // if a date exists in the select schedule
        if(this.selectSchedule.event_dates[index]){
          // push the start and end dates into component state array
          this.events.startDates.push(this.dateToTime(this.selectSchedule.event_dates[index].first_day, true));
          this.events.endDates.push(this.dateToTime(this.selectSchedule.event_dates[index].last_day, true));
        }
      }
      
    },
    checkAvailibility(month, day){
      // Checks availability given month: (String), day: (Number)
      // converts month, day to Unix Number
      // If that number is inbetween a start time and end time (inside of events array)
      // returns true,
      // else returns false
      var date_check = this.customDateFormatter(month, day);
      for(let index = 0; index < this.events.startDates.length; index++) {
        if(this.isInRange(this.events.startDates[index], this.events.endDates[index], date_check)){
          if (this.selectSchedule.event_dates[index].available) {
            return true;
          } else{
            return false;
          }
        }
      }
    },
    mailtoSetup(month, day){
      // Set up mailto: hyperlink
      let date_label = '';
      var date_check = this.customDateFormatter(month, day);
      for(let index = 0; index < this.events.startDates.length; index++) {
        if(this.isInRange(this.events.startDates[index], this.events.endDates[index], date_check)){
          if (this.selectSchedule.event_dates[index].available) {
            date_label = this.selectSchedule.event_dates[index].time_label;
            console.log(date_label);
          } 
        }
      }
      if (this.checkAvailibility(month, day)) {
        let subject = 'Andromeda inquiry, '+ this.selectSchedule.label +', '+ date_label;
        //console.log(subject);
        window.location.href = 'mailto:info@bartonandgray.com?subject='+ encodeURIComponent(subject);
      }
    },
    tooltipHandler(month, day){
      // Styles the tooltip onclick
      let tooltip_str = "Unavailable";
      var date_check = this.customDateFormatter(month, day);
      for(let index = 0; index < this.events.startDates.length; index++) {
        if(this.isInRange(this.events.startDates[index], this.events.endDates[index], date_check)){
          // maybe switch here, will refactor 
          if (!this.selectSchedule.event_dates[index].available) {
            // Reserved
            tooltip_str = 'Reserved';
          } else{ // is available
            if (this.selectSchedule.event_dates[index].isEvent) {
              // Event
              tooltip_str = this.selectSchedule.event_dates[index].full_date + ' - '+ this.selectSchedule.event_dates[index].desc+ ' ' + this.selectSchedule.event_dates[index].price;
            } else {
              // Available, not event
              tooltip_str = this.selectSchedule.event_dates[index].time_label +' '+ this.selectSchedule.event_dates[index].price_label;
            }
          }
        }
      }
      return tooltip_str;
    },
    async getTestData(){
      // Adds test data and saves to this.test_data
      // http://www.bartonandgray.localhost:5555/events/sailing-cat-bahamas/2023/sailing-cat
      //let url = '/events/sailing-cat-bahamas/2023/sailing-cat';
      let url = '/events/sailing-cat-'+ this.region +'/2023/sailing-cat';
      
      try {
        let response = await fetch(url, {
          method: 'GET',
          headers:{
            'Content-Type': 'application/json'
          }
        });
        let finalRes = await response.json();
        this.selectSchedule = finalRes;
      } catch(error){
        console.log("Error: ", error);
      } finally{
        this.isFetching = false;
        this.generateSelectMonths(this.selectSchedule);
        this.generateStartEndOfCalMonths();
        this.populateEvents();
      }
    }
  },
  computed:{
    
  },
  mounted() {
    this.getTestData();
    //if(this.useCustomData){
      //this.getTestData();
    //} else {
      // Narrows data in JSON down to region
      //this.selectRegion();
      // Gets all months in the current schedule and saves them to "cal_months[]"
      //this.generateSelectMonths(this.selectSchedule);
      // Gets the first and last days of each cal_month and saves them to first and last day arrays
      //this.generateStartEndOfCalMonths();
      // Saves the start and end dates for each item in selectSchedule.dates[]
      // Note: events in the data file are handled differently from these events
      //this.populateEvents(); 
    //}
  },
}
</script>
<style lang="scss" scoped>
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/colors';
@use 'stylesheets-bridge/animations';
/* Loses calendar container padding at about 380px */
$available: #D6F6E1;
$reserved: #FAC8C8;
$event: #fceac3;
$text: #3B648A;
$default: #D9D9D9;
.calendar{
  display: inline-grid;
  justify-content: center;
  align-items: center;
  .cal-header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    .year{
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .cal-body{
    .weekdays{
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(7, 1fr);
      color: $text;
      font-weight: 600;
      margin-bottom: 15px;
      text-align: center;
      span{
        width: 50px;
        justify-self: center;
        align-self: center;
        text-align: center;
      }
    }
  }
  @include breaks.bg-breakpoint('very-narrow'){
    /* Prevent the tooltip from overflowing off the page on mobile */
    overflow: hidden;
  }
}
.days{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.day{
  cursor: pointer;
  outline: 0;
  background: transparent;
  background-color: $default;
  color: $text;
  font-size: 16px;
  justify-self: center;
  align-self: center;
  text-align: center;
  display: flex; /* center text inside */
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 2px;
  transition-duration: .2s;
  /* Weekday shift */
  &.shift{
    opacity: 0;
    user-select: none;
    cursor: auto;
  }
  &:hover{
    filter: brightness(90%);
  }
  &.active{
    .day-tooltip{
      display: inline-flex;
    }
  }
  /* COLORING */
  &.available{
    background-color: $available;
    &.event{
      background-color: $event;
    }
  }
  &.reserved{
    background-color: $reserved;
  }
  /* Mainly handles tooltip spacing */
  @include breaks.bg-breakpoint('very-narrow'){
    /* FIRST ROW */
    &:nth-child(7n-6){
      .day-tooltip{
        margin-left: 20em;
        // 30%
      }
    }
    /* SECOND ROW */
    &:nth-child(7n-5){
      .day-tooltip{
        margin-left: 13.5em;
        // -75%
      }
    }
    /* THIRD ROW */
    &:nth-child(7n-4){
      .day-tooltip{
        margin-left: 7em;
        // -185%
      }
    }
    /* FOURTH ROW  (MIDDLE) */
    &:nth-child(7n-3){
      .day-tooltip{
        
      }
    }
    /* FIFTH ROW */
    &:nth-child(7n-2){
      .day-tooltip{
        margin-right: 7em;
        // -185%
      }
    }
    /* SIXTH ROW */
    &:nth-child(7n-1){
      .day-tooltip{
        margin-right: 13.5em;
        // 25%
      }
    }
    /* SEVENTH ROW */
    &:nth-child(7n){
      .day-tooltip{
        margin-right: 20em;
        // 30%
      }
    }
  }
}
.day-tooltip{
  display: none; /* change me for testing */
  position: absolute;
  color: $text;
  background-color: $default;
  margin-bottom: 3em;
  animation: fadeIn ease-in-out .5s;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: center;
  align-items: center;
  &.available{
    background-color: $available;
    margin-bottom: 5em;
  }
  &.reserved{
    background-color: $reserved;
  }
  &.event{
    background-color: $event;
    margin-bottom: 5em;
  }
  @include breaks.bg-breakpoint('very-narrow'){
    position: absolute;
  }
}
.day-tooltip-content{
  color: $text;
  margin: 0;
  height: 2em;
  width: 15em;
  display: flex;
  align-items: center;
  justify-content: center;
  &.available{
    background-color: $available;
    height: 4em;
  }
  &.reserved{
    background-color: $reserved;
  }
  &.event{
    background-color: $event;
    height: 4em;
  }
  @include breaks.bg-breakpoint('very-narrow'){
    padding-left: 0.5em;
  }
}
.day-tooltip-button{
  margin: .5em;
}
.bg-menu{
  margin: 0.5em;
  .on{
    .bg-menu-label{
      padding: 0 0.75em;
    }
  }
}
.cal-number{
  // Make "click-throughable"
  user-select: none;
  pointer-events: none;
}
.currentMonth_Year{
  letter-spacing: 0.16em;
  font-weight: 350;
}
</style>