<template>
  <!-- DESKTOP NAV -->
  <div class="cruising-navigation">
    <!-- Change navArray to sortedArray -->
    <div v-for="item in navArray" v-bind:key="item" class="cnav-item" v-bind:class="item">
      <div :class="classHandler(item)"></div>
      <img :class="chevronFilter(item)" src="../../../../assets/images/icons/chevrons/arrow-next.svg">
      <div class="cnav-item-label enforce-p-large">
        {{ labeller(item) }}
      </div>
    </div>
  </div>
  <!-- Mobile Nav, active moved to top -->
  <div class="cruising-navigation is-mobile">
    <div v-for="item in sortedArray" v-bind:key="item" class="cnav-item" v-bind:class="item">
      <div :class="classHandler(item)"></div><!-- First item will always be "active" in mobile -->
      <img :class="chevronFilter(item)" src="../../../../assets/images/icons/chevrons/arrow-next.svg">
      <div class="cnav-item-label enforce-p-large">
        {{ labeller(item) }}
      </div>
    </div>
    
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['step']);
  const stepValue = ref(props.step);
  let navArray = ["destinations", "timeframe", "activities", "contact"];
  let isActive = navArray[stepValue.value];
  
  // Mobile
  let navArrayClone = [...navArray] 
  let first = isActive;
  let sortedArray = navArrayClone.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0;});
  let temp = sortedArray.shift()
  sortedArray.push(temp)  

  function chevronFilter(item){
    switch (item) {
      case 'destinations':
        return 'filter-fblue'
      case 'timeframe':
        return 'filter-gold'
      case 'activities':
        return 'filter-gold'
      case 'contact':
        return 'filter-fblue'
      default:
        return 'ERROR';
    }
  }

  function labeller(item){
    switch (item) {
      case 'destinations':
        return 'DESTINATIONS'
      case 'timeframe':
        return 'TIMEFRAME'
      case 'activities':
        return 'ACTIVITIES & CUISINE'
      case 'contact':
        return 'CONTACT'
      default:
        return 'ERROR';
    }
  }

  function classHandler(i){
    if (isActive == i) {
      return 'active'
    } else {
      return;
    }
  }

  watch(() => props.step, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    stepValue.value = newValue;
    isActive = navArray[stepValue.value];
  }); 
</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/v3/abstracts/colors';

.cruising-navigation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3em auto 5em auto;
  @media only screen and (max-width: 1280px){
    display: none;
    &.is-mobile{
      display: flex;
      flex-direction: column;
    }
  }
  @media only screen and (min-width: 1281px){
    &.is-mobile{
      display: none;
    }
  }

  .cnav-item{
    width: 360px;
    height: 100px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 0.5em;
    align-items: center;
    transition: 0.2s ease-in-out;
    @media only screen and (max-width: 1440px){
      width: 300px;
    }
    @media only screen and (max-width: 1280px){
      width: 360px;
      max-width: 100%;
    }
    @media only screen and (max-width: 370px){
      width: 300px;
    }
    img{
      max-width: 30px;
    }
    .active{
      position: absolute;
      width: 0;
      height: 0;
      margin-top: 200px;
      border: 50px solid transparent;
      border-top: 0;
    }
    &.contact{
      background: $gradient-sun !important;
      .cnav-item-label{
        color: $v3-navy-blue; // Fallback color for unsupported browsers
        background-image: $gradient-shark;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
      .active{
        border-top: 50px solid $v3-gold;
      }
    }
    &.destinations{
      background: $gradient-silver !important;
      .cnav-item-label{
        color: $v3-navy-blue; // Fallback color for unsupported browsers
        background-image: $gradient-shark;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
      .active{
        border-top: 50px solid $v3-hull;
      }
    }
    &.timeframe{
      background: $gradient-sky !important;
      .cnav-item-label{
        color: $v3-hull; // Fallback color for unsupported browsers
        background-image: $gradient-cloud;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
      .active{
        border-top: 50px solid $v3-french-blue;
      }
    }
    &.activities{
      background: $gradient-shark !important;
      .cnav-item-label{
        color: $v3-hull; // Fallback color for unsupported browsers
        background-image: $gradient-silver;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
      .active{
        border-top: 50px solid #324954;
      }
    }
  }
}

.filter-navy{
  filter: invert(32%) sepia(16%) saturate(793%) hue-rotate(163deg) brightness(95%) contrast(93%);
}

.filter-gold{
  filter: invert(93%) sepia(23%) saturate(5513%) hue-rotate(322deg) brightness(113%) contrast(103%);
}

.filter-fblue{
  filter: invert(50%) sepia(42%) saturate(680%) hue-rotate(169deg) brightness(92%) contrast(86%);
}
</style>