<template>
  <div v-if="!isLoading"  class="squadron-container">

    <!-- DAYCHASER -->
    <div class="squadron-section daychaser">
      <a href="/fleet/daychaser">
        <div class="fleet-landing-caption">
          <img class="fleet-chevvron" style="" src="https://cloud.bartonandgray.com/library/fleet/landing/Chevron-hi-res.png">
          <div class="fleet-landing-caption-text">
            <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.daychaser.label, true) }}</div>
            <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.daychaser.label, false) }}</div>
          </div>
        </div>
      </a>
      <div class="vessel-wrapper">
        <div v-for="vessel in squadron_data.daychaser.vessels" v-bind:key="vessel" class="vessel">
          <img :src="squadron_data.daychaser.icon_url">
          <div class="squadron-vessel-label">{{ vessel }}</div>
        </div>
      </div>
    </div>

    <!-- T44 -->
    <div class="squadron-section talaria">
      <a href="/fleet/t44">
        <div class="fleet-landing-caption">
          <img class="fleet-chevvron" style="" src="https://cloud.bartonandgray.com/library/fleet/landing/Chevron-hi-res.png">
          <div class="fleet-landing-caption-text">
            <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.talaria.label, true) }}</div>
            <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.talaria.label, false) }}</div>
          </div>
        </div>
      </a>
      <div class="vessel-wrapper">
        <div v-for="vessel in squadron_data.talaria.vessels" v-bind:key="vessel" class="vessel">
          <img :src="squadron_data.talaria.icon_url">
          <div class="squadron-vessel-label">{{ vessel }}</div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="multi-squad-section">
      <div class="squadron-section picnic">
        <a href="/fleet/picnic">
          <div class="fleet-landing-caption">
            <img class="fleet-chevvron" style="" src="https://cloud.bartonandgray.com/library/fleet/landing/Chevron-hi-res.png">
            <div class="fleet-landing-caption-text">
              <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.picnic.label, true) }}</div>
              <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.picnic.label, false) }}</div>
            </div>
          </div>
        </a>
        <div class="vessel-wrapper">
          <div v-for="vessel in squadron_data.picnic.vessels" v-bind:key="vessel" class="vessel">
            <img :src="squadron_data.picnic.icon_url">
            <div class="squadron-vessel-label">{{ vessel }}</div>
          </div>
        </div>
      </div>

      <div class="multi-squad-section">
        <div class="squadron-section catamaran">
          <a href="/fleet/lagoon62">
            <div class="fleet-landing-caption">
              <img class="fleet-chevvron" style="" src="https://cloud.bartonandgray.com/library/fleet/landing/Chevron-hi-res.png">
              <div class="fleet-landing-caption-text">
                <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.catamaran.label, true) }}</div>
                <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.catamaran.label, false) }}</div>
              </div>
            </div>
          </a>
          <div class="vessel-wrapper">
            <div v-for="vessel in squadron_data.catamaran.vessels" v-bind:key="vessel" class="vessel">
              <img :src="squadron_data.catamaran.icon_url">
              <div class="squadron-vessel-label">{{ vessel }}</div>
            </div>
          </div>
        </div>

        <div class="squadron-section whaler">
          <a href="/fleet/boston-whaler">
            <div class="fleet-landing-caption">
              <img class="fleet-chevvron" style="" src="https://cloud.bartonandgray.com/library/fleet/landing/Chevron-hi-res.png">
              <div class="fleet-landing-caption-text">
                <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.whaler.label, true) }}</div>
                <div class="fleet-landing-header">{{ vesselNameTrimmer(squadron_data.whaler.label, false) }}</div>
              </div>
            </div>
          </a>
          <div class="vessel-wrapper">
            <div v-for="vessel in squadron_data.whaler.vessels" v-bind:key="vessel" class="vessel">
              <img :src="squadron_data.whaler.icon_url">
              <div class="squadron-vessel-label">{{ vessel }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div v-if="isLoading" style="margin: 3em auto; display: flex; justify-content: center;">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import squadron_static from './squadron.json'

let isDebug = false;
let isLoading = ref(true);
let squadron_data = ref();

async function getSquadData(){
  let url = isDebug ? 'http://www3-dev.bartonandgray.com:5555/fleet/squadron-data' : 'https://edge.bartonandgray.com/fleet/squadron-data';
  
  try {
    let response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: "no-cache",
      credentials: "same-origin",
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
    let finalRes = await response.json();
    squadron_data.value = finalRes;
    isLoading.value = false;
  } catch(e){
    console.log("Error: ", e);

  } finally{
    isLoading.value = false;
    console.log('squadron_data: ', squadron_data.value);
  }
}

function vesselNameTrimmer(fullname, isBrand){
  let nameArray = fullname.split(" ");
  if(isBrand){
    return nameArray[0] == 'B&G' ? 'BARTON & GRAY' : nameArray[0];
  } else {
   nameArray = nameArray.slice(1);
    return nameArray.join(' ');
  }
}

onMounted(() => {
  console.log('OG Squadron: ', squadron_static);
  getSquadData();
});

</script>
<style scoped lang="scss">
@import '../../../../assets/stylesheets/colors.scss';

.squadron-container{
  @media only screen and (max-width: 480px){
    padding: 0 1em;
  }
}

.squadron-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  //max-width: 480px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5em;

  &.daychaser{
    img{
      max-width: 63px;
      height: auto;
    }
    @media only screen and (max-width: 1280px){
      img{
        max-width: 120px;
      }
    }
    @media only screen and (min-width: 1280px){
      img{
        max-width: 200px;
      }
    }
  }

  &.talaria{
    img{
      max-width: 57px;
      height: auto;
      
    }
    @media only screen and (min-width: 1280px){
      img{
        max-width: 175px;
      }
    }

    @media only screen and (max-width: 1280px){
      img{
        max-width: 125px;
      }
    }
  }

  &.picnic{
    img{
      max-width: 49px;
      height: auto;
    }
    @media only screen and (min-width: 1280px){
      img{
        max-width: 150px;
      }
    }
    @media only screen and (max-width: 1280px){
      img{
        max-width: 120px;
      }
    }
  }

  &.catamaran{
    img{
      max-width: 157px;
      height: auto;
    }
    @media only screen and (min-width: 1280px){
      img{
        max-width: 314px;
      }
    }
  }

  &.whaler{
    img{
      max-width: 42px;
      height: auto;
    }
    @media only screen and (min-width: 1280px){
      img{
        max-width: 175px;
      }
    }
    @media only screen and (max-width: 1280px){
      img{
        max-width: 125px;
      }
    }
  }

  .vessel-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .squadron-vessel-label{
      font-size: 21px;
    }
  }

  .squadron-section-label{
    color: $v3-gold;
    font-size: 2em;
    font-style: normal;
    line-height: 123.5%;
    letter-spacing: 2.05px;
  }
}

.multi-squad-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  //max-width: 480px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5em;
}

.vessel{
  squadron-vessel-label{
    text-align: center;
  }
}

@media only screen and (max-width: 1280px){
  .multi-squad-section{
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
};

@media only screen and (min-width: 1280px){
  .squadron-section{
    //max-width: 960px;
  }

  .multi-squad-section{
    //max-width: 960px;
  }
}

@media only screen and (max-width: 1280px){
  .squadron-section-label{
    font-size: 1.5em !important;
  }
  .squadron-vessel-label{
    font-size: 1em !important;
  }
}


.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 10px solid yellow;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4a8ac9 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fleet-landing-caption{
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5em;
  padding-top: 1.5em;
  margin: auto;
  .fleet-chevvron{
    width: 65px !important;
    height: 55px !important;
    @media only screen and (max-width: 400px){
      width: 52.8px !important;
      height: 44px !important;
    }
  }

  .fleet-landing-caption-text{
    font-size: 2em;
    font-weight: 700;
    line-height: 109%;
    letter-spacing: 0.96px;
    text-align: left;
    color: $storm;
    .fleet-landing-header{
      &:first-child{
        color: $v3-french-blue;
      }
    }
  }
}


</style>