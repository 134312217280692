<template>
  <div class="layout-2col">
    <!-- Left -->
    <div class="col-left">
      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Contact</div>
        <p class="v4-base-paragraph color-storm">
          Please tell us a little more about yourself so we craft a great voyage for you. 
        </p>
      </div>

      <!-- Destinations -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">DESTINATIONS</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="destination in destinationsValue" v-bind:key="destination">{{ destination }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 0)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT DESTINATIONS</div>
          </div>
        </div>
      </div>

      <!-- Timeframe -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">TIMEFRAME</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="date in datesValue" v-bind:key="date">{{ date }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 1)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT TIMEFRAMES</div>
          </div>
        </div>
      </div>

      <!-- Activities -->
      <div>
        <div class="v4-base-header-nova color-gradient-shark">ACTIVITIES &AMP; CUISINE</div>
        <ul class="v4-bullet-list" style="margin-top: 0; margin-bottom: 0;">
          <li class="color-storm" v-for="option in optionsValue" v-bind:key="option">{{ option }}</li>
        </ul>

        <!-- Button -->
        <div style="width: 300px; max-width: 100%; margin: 2em 0 3em 0;">
          <div @click="$emit('changeStep', 2)" class="v4-download-button bg-color-gradient-cloud">
            <div class="soft-square bg-color-gradient-sky">
              <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
            </div>
            <div class="button-label color-gradient-shark">EDIT ACTIVITIES &AMP; CUISINE</div>
          </div>
        </div>
      </div>

    </div>

    <!-- Right -->
    <div class="col-right">

      <!-- Fname -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">First Name</div>
        <input class="input-option" v-model="fname" />
      </div>

      <!-- Lname -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">Last Name</div>
        <input class="input-option" v-model="lname" />
      </div>

      <!-- Email -->
      <div class="contact-input" style="display: flex; flex-direction: column;">
        <div class="v4-base-header-nova color-gradient-shark">Email</div>
        <input class="input-option" :class="validateEmail(email) ? 'valid' : 'invalid'" v-model="email" type="email" />
        <div v-if="!validateEmail(email)" style="color: red;">must be in email format</div>
      </div>

      <!-- Phone -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">Phone</div>
        <input class="input-option" v-model="phone" />
      </div>

      <!-- # of Guests -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">Number of guests on your cruise?</div>
        <select style="width:100px" class="input-list-dropdown" v-model="numGuests">
          <option v-for="index in 8" :key="index">{{ index }}</option>
        </select>
      </div>
      
      <!-- How did you hear -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">How did you hear about us?</div>
        <select style="width: 400px" class="input-list-dropdown" v-model="hearAbout">
          <option v-for="choice in hearAboutChoices" :key="choice">{{ choice }}</option>
        </select>
      </div>

      <!-- Button -->
      <div v-if="isReady()" @click="$emit('close', userData)" style="width: 300px; max-width: 100%; margin: 3em 0; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">SAVE YOUR VOYAGE</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations', 'dates', 'options']);
  const destinationsValue = ref(props.destinations);
  const datesValue = ref(props.dates);
  const optionsValue = ref(props.options);

  const fname = ref('');
  const lname = ref('');
  const email = ref('');
  const phone = ref('');
  const numGuests = ref();
  const hearAbout = ref('');
  const hearAboutChoices = ref(['I am a B&G Member', 'From a B&G Member', 'From a friend', 'Newspaper / Magazine', 'Club / Resort', 'Blog, Google, Website'])

  const userData = ref({fname, lname, email, phone, numGuests, hearAbout})

  function isReady(){
    let flag = 0;

    if (!validateEmail(email.value)) {
      flag = 1;
    }

    if((fname.value == '') || (lname.value == '') || (email.value == '') || (phone.value == '') || (numGuests.value == null) || (hearAbout.value == '')){
      flag = 1;
    }

    if (flag == 0) {
      return true;
    } else {
      return false;
    }
  }

  function validateEmail(email_text){
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email_text.match(mailformat) || email_text == '') {
      return true;
    } else {
      return false;
    } 
  }

  watch(() => props.destinations, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    destinationsValue.value = newValue;
  }); 

  watch(() => props.dates, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    datesValue.value = newValue;
  }); 

  watch(() => props.options, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    optionsValue.value = newValue;
  }); 
</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .legend-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .legend-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
    }
  }

  .checkmark{
    path{
      fill: $v3-french-blue;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

  .contact-input{
    margin: 1em 0;
    margin-bottom: 1.5em;
    .v4-base-header-nova{
      margin-bottom: 0.25em;
    }
  }

  .input-option{
    background: $gradient-cloud !important;
    border: 1px dashed black;
    width: 400px;
    max-width: 100%;
    padding: 0.75em 0 0.75em 1em;
    transition: 0.15s ease-in-out;
    &:hover{
      filter: brightness(90%);
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
    &.invalid{
      border: 2px solid red;
      &:focus{
        outline: none;
      }
    }
  }

  .input-list-dropdown{
    max-width: 100%;
    height: 30px;
    border: 1px dashed black;
    background: $gradient-cloud !important;
  }
</style>