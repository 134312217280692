import {windowBg} from 'WindowBg/window_bg'
if(process.env.NODE_ENV == 'development'){
  console.log("tnwbis ", windowBg)
}
const hamburgerOpen = "https://cloud.bartonandgray.com/library/ux/hamburger.svg"
const hamburgerClosed = "https://cloud.bartonandgray.com/library/ux/hamburger-close.svg";

class BgTopNav {
  
  touched() {
    var ne = this.navElement();
    // when first loading, it has a style display none
    ne.style.display = 'block';
    ne.classList.toggle('active');
    if (document.getElementById('hamburger').src == hamburgerOpen){
      document.getElementById('hamburger').src = hamburgerClosed;
    }
    else{
      document.getElementById('hamburger').src = hamburgerOpen;
    }
    this.anyTouched();
  }
  close() {
    document.getElementById('hamburger').src = hamburgerOpen;
    var ne = this.navElement();
    ne.classList.remove('active');
    this.anyTouched();
  }
  
  anyTouched() {
    var bgce = window.bgChartExperience.component;
    bgce.externalDismiss()
  }
  
  bgChartPulldownToggle(el) {
    var x = el.closest('.bg-button-holder').querySelector('.slide-down-container').classList; 
    if (x.contains('active')) {
      x.remove('active'); 
      x.add('inactive')
    } else {
      x.remove('inactive'); 
      x.add('active')
    }

    this.anyTouched();
  }
  
  navElement() {
    return window.document.querySelector('.bg-slidein-nav-element')
  }
  
}

windowBg.topNav = new BgTopNav();


