<template>
  <div class="bg-image-holder" v-if="isLogoActive" v-on:click="flip()">
    <img src="https://cloud.bartonandgray.com/library/brand/bg-logo-gold-white.svg">
  </div>
  <div class="lenav-wrapper" v-if="!isLogoActive" v-on:click="flip()">
    <ul class="le-nav-list">
      <li><a href="/jobs">JOBS</a></li>
      <li><a href="/events">EVENTS</a></li>
      <li><a href="/partners">PARTNERS</a></li>
      <li><a href="/about">ABOUT</a></li>
      <li><a href="/inquire">INQUIRE</a></li>
      <li><a href="">MEMBER LOGIN</a></li>
    </ul>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const isLogoActive = ref(true);

function flip(){
  isLogoActive.value ? isLogoActive.value = false : isLogoActive.value = true;
}
</script>
<style lang="scss" scoped>
  .lenav-wrapper{
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: #D9D9D9;
    animation: fadeInFromNone 0.5s ease-in-out;
    .le-nav-list{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: auto;
      margin: auto;
      height: 100% ;
      width: 100%;
      overflow: hidden;
      list-style-type: none;
      padding: 0;
      li{
        font-size: 1em;
        margin: 0.25em;
        letter-spacing: 0.185em;
        transition: 0.2s ease-in-out;
        color: #324954;
        &:hover{
          color: #4a8ac9;
        }
      }
    }
  }

</style>