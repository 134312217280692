import {MKHTTP} from 'src/Mwk/MKHTTP'

class PortService {
  
  listForInquiry() {
    var url = "/public/ports/list_for_inquiry"
    
    var promise = new Promise(function(resolve, reject) {
      MKHTTP.get(url).then(function(resp) {
        resolve(resp.data.ports);
      })
    })
    return promise;

    
  }
  
  publicForKey(key) {
    var url = `/public/ports/${key}/public_for_key`
    
    var promise = this.fetchPortObjectPromise(url);

    return promise;
  }
  
  marketHarborForKey(key) {
    var url = `/public/ports/${key}/market_harbor_for_key`
    
    var promise = this.fetchPortObjectPromise(url);
    
    return promise;
  }
  
  fetchPortObjectPromise(url) {
    return new Promise(function(resolve, reject) {
      MKHTTP.get(url).then(function(resp) {
        resolve(resp.data.port);
      })
    })
    
  }
  
  foo() {
    return 'bar'
  }
  
  
}

var portService = new PortService()

export {portService}
